import React, { useEffect, useRef } from 'react';

const BannerBottomCarousel = () => {

    return (
        <div>
            {/* <div class="position-relative marquee-container d-none d-sm-block">
                <div class="marquee d-flex justify-content-around">
                    <span>1 month <br /> Intensive</span>
                    <span>80+ Hours of <br /> Content</span>
                    <span>52 Weeks <br /> Extended Support</span>
                    <span>Blended Offline & <br /> Online Mode</span>
                    <span>End-to-End <br /> Training</span>
                    <span>Hands-On <br /> Learning</span>
                    <span>16 <br /> Modules</span>
                </div>
                <div class="marquee marquee2 d-flex justify-content-around">
                    <span>1 month <br /> Intensive</span>
                    <span>80+ Hours of <br /> Content</span>
                    <span>52 Weeks <br /> Extended Support</span>
                    <span>Blended Offline & <br /> Online Mode</span>
                    <span>End-to-End <br /> Training</span>
                    <span>Hands-On <br /> Learning</span>
                    <span>16 <br /> Modules</span>
                </div>
            </div> */}
            <div className="scroll">
                <div>
                    <span>1 Month  Intensive</span>
                    <span>80+ Hours of  Content</span>
                    <span>52 Weeks  Extended Support</span>
                    <span>Blended Offline &  Online Mode</span>
                    <span>End-to-End  Training</span>
                    <span>Hands-On Learning</span>
                    <span>21 Modules</span>
                </div>
                <div>
                    <span>1 Month  Intensive</span>
                    <span>80+ Hours of  Content</span>
                    <span>52 Weeks Extended Support</span>
                    <span>Blended Offline &  Online Mode</span>
                    <span>End-to-End  Training</span>
                    <span>Hands-On Learning</span>
                    <span>21 Modules</span>
                </div>
            </div>
        </div>
    )
}

export default BannerBottomCarousel