import React from 'react'

const CourseBanner = () => {
  return (
    <div className='home-banner overflow-hidden' >
      <div className=''>
        <h1 className='banner-heading mb-5' data-aos="zoom-in" data-aos-duration="1500">“Digital  Entrepreneurship Initiator” <br className='break-tag' /> Program</h1>
        <div className='flexRow mt-lg-0 mt-md-0 mt-4' data-aos="zoom-in" data-aos-duration="1500">
          <a href="https://optin.socialeagle.in/#/apply" target='_blank'>
             <button type="button" class="btn btn-book-expert-call">Book Expert Call</button>
          </a>
          {/* <button type="button" class="btn btn-explore-course">Explore Course</button> */}
        </div>
      </div>
    </div>
  )
}

export default CourseBanner