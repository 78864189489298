import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import Results from "./Pages/Results";
import ContactUs from "./Pages/ContactUs";
import Course from "./Pages/Course";
import Experts from "./Pages/Experts";
import TermsndCondition from "./Pages/TermsndCondition";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import RefundPolicy from "./Pages/RefundPolicy";
import Blogs from "./Pages/Blogs";
import Events from "./Pages/Events";
import BecomeADigitalMarketingSpecialist from "./Components/BlogDetails/BecomeADigitalMarketingSpecialist";
import TheCompleteDigitalMarketingCourse from "./Components/BlogDetails/TheCompleteDigitalMarketingCourse";
import SixPillarsofDigitalMarketing from "./Components/BlogDetails/SixPillarsofDigitalMarketing";
import HowtostartaDigitalMarketingAgency from "./Components/BlogDetails/HowtostartaDigitalMarketingAgency";
import TopDigitalMarketingProjectIdeas from "./Components/BlogDetails/TopDigitalMarketingProjectIdeas";
import TypesofDigitalMarketing from "./Components/BlogDetails/TypesofDigitalMarketing";
import DigitalMarketingCourseAfter10th from "./Components/BlogDetails/DigitalMarketingCourseAfter10th";
import MBAMarketingProjectTopics from "./Components/BlogDetails/MBAMarketingProjectTopics";
import DigitalMarketingTrends from "./Components/BlogDetails/DigitalMarketingTrends";
import ScopeofDigitalMarketing from "./Components/BlogDetails/ScopeofDigitalMarketing";
import DigitalMarketingCourseAfter12th from "./Components/BlogDetails/DigitalMarketingCourseAfter12th";
import DigitalMarketingJobsforfreshers from "./Components/BlogDetails/DigitalMarketingJobsforfreshers";

const Main = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Aboutus" element={<AboutUs />} />
        <Route path="/Results" element={<Results />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/Course" element={<Course />} />
        <Route path="/Experts" element={<Experts />} />
        <Route path="/Events" element={<Events />} />
        <Route path="/Blogs" element={<Blogs />} />

        <Route path="/how-to-become-a-digital-marketing-specialist" element={<BecomeADigitalMarketingSpecialist />} />
        <Route path="/the-complete-digital-marketing-course" element={<TheCompleteDigitalMarketingCourse />} />
        <Route path="/six-pillars-of-digital-marketing" element={<SixPillarsofDigitalMarketing />} />
        <Route path="/how-to-start-a-digital-marketing-agency" element={<HowtostartaDigitalMarketingAgency />} />
        <Route path="/top-digital-marketing-project-ideas" element={<TopDigitalMarketingProjectIdeas />} />
        <Route path="/types-of-digital-marketing-a-comprehensive-guide" element={<TypesofDigitalMarketing />} />
        <Route path="/digital-marketing-course-after-10th" element={<DigitalMarketingCourseAfter10th />} />
        <Route path="/MBA-marketing-project-topics" element={<MBAMarketingProjectTopics />} />
        <Route path="/digital-marketing-trends" element={<DigitalMarketingTrends />} />
        <Route path="/scope-of-digital-marketing" element={<ScopeofDigitalMarketing />} />
        <Route path="/digital-marketing-coursea-fter-12th" element={<DigitalMarketingCourseAfter12th />} />
        <Route path="/digital-marketing-jobs-for-freshers" element={<DigitalMarketingJobsforfreshers />} />

        <Route path="/TermsandConditions" element={<TermsndCondition />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/RefundandCancellationPolicy" element={<RefundPolicy />} />
      </Routes>
    </HashRouter>
  );
};

export default Main;
