import React, { useEffect, useRef } from "react";
import rb1 from "../../assets/images/results-banner/1.webp";
import rb2 from "../../assets/images/results-banner/2.webp";
import rb3 from "../../assets/images/results-banner/3.webp";
import rb4 from "../../assets/images/results-banner/4.webp";
import rb5 from "../../assets/images/results-banner/5.webp";
import rb6 from "../../assets/images/results-banner/6.webp";
import rb7 from "../../assets/images/results-banner/7.webp";
import rb8 from "../../assets/images/results-banner/8.webp";
import rb9 from "../../assets/images/results-banner/9.webp";
import rb10 from "../../assets/images/results-banner/10.webp";
import rb11 from "../../assets/images/results-banner/11.webp";
import rb12 from "../../assets/images/results-banner/12.webp";
import rb13 from "../../assets/images/results-banner/13.webp";
import rb14 from "../../assets/images/results-banner/14.webp";
import rb15 from "../../assets/images/results-banner/15.webp";
import rb16 from "../../assets/images/results-banner/16.webp";
import rb17 from "../../assets/images/results-banner/17.webp";

import rb20 from "../../assets/images/results-banner/20.webp";
import rb21 from "../../assets/images/results-banner/21.webp";
import rb22 from "../../assets/images/results-banner/22.webp";
import rb23 from "../../assets/images/results-banner/23.webp";
const predefinedPositions = {
  desktop: [
    { x: -40, y: -20 },
    { x: -30, y: 3 },
    { x: -20, y: -23 },
    { x: 10, y: 39 },
    { x: -8, y: -26 },
    { x: 30, y: 30 },
    { x: 45, y: 22 },
    { x: -45, y: 35 },
    { x: 38, y: 0 },
    { x: 18, y: -26 },
    { x: 45, y: -26 },
    { x: -10, y: 37 },
    { x: -20, y: 21 },
    { x: -34, y: 35 },
    { x: -29, y: -28 },
    { x: 5, y: -32 },
    { x: 34, y: -28 },
    // { x: -24, y: 42 },
    // { x: 46, y: 14 },
    { x: 20, y: 41 },
    { x: 27, y: 1 },
    { x: -43, y: 3 },
    { x: 0, y: 28 },
  ],
  tablet: [
    { x: -40, y: -14 },
    { x: -44, y: 3 },
    { x: -20, y: -13 },
    { x: 10, y: 16 },
    { x: 0, y: -10 },
    { x: 30, y: 21 },
    { x: 45, y: 18 },
    { x: -45, y: 19 },
    { x: 38, y: 0 },
    { x: 23, y: -14 },
    { x: 45, y: -14 },
    { x: -7, y: 11 },
    { x: -24, y: 19 },
    { x: -24, y: 19 },
    { x: -24, y: 19 },
    { x: -24, y: 19 },
    { x: -24, y: 19 },
    // { x: -24, y: 19},
    // { x: -24, y: 19},
    { x: -24, y: 19 },
    { x: -24, y: 19 },
    { x: -24, y: 19 },
    { x: -24, y: 19 },
  ],
  mobile: [
    { x: -45, y: -29 },
    { x: -38, y: 10 },
    { x: -22, y: -31 },
    { x: 10, y: 44 },
    { x: 0, y: -25 },
    { x: 30, y: 22 },
    { x: 38, y: 39 },
    { x: -41, y: 45 },
    { x: 41, y: 5 },
    { x: 23, y: -14 },
    { x: 45, y: -26 },
    { x: -5, y: 18 },
    { x: -19, y: 34 },
    { x: -40, y: -11 },
    { x: -40, y: 27 },
    { x: 23, y: -33 },
    { x: 12, y: 30 },
    // { x: 34, y: 47 },
    // { x: -15, y: -15 },
    { x: -14, y: 48 },
    { x: -24, y: 23 },
    { x: -19, y: 34 },
    { x: -19, y: 34 },
  ],
};

const ResultsBanner = () => {
  const imagesRef = useRef([]);

  useEffect(() => {
    const getScreenType = () => {
      if (window.innerWidth <= 768) return "mobile";
      if (window.innerWidth <= 1024) return "tablet";
      return "desktop";
    };

    const screenType = getScreenType();
    const positions = predefinedPositions[screenType];

    const setFixedPosition = (img, index) => {
      const { x, y } = positions[index];
      img.style.transform = `translate(${x}vw, ${y}vh)`;
    };

    // imagesRef.current.forEach((img, index) => {
    //   img.style.opacity = 1;
    //   img.style.transition = 'transform 5s ease-out';
    //   setFixedPosition(img, index);
    // });

    imagesRef.current.forEach((img, index) => {
      if (img) {
        setFixedPosition(img, index);
      }
    });
    // Optionally, you can add a listener to handle window resizing
    const handleResize = () => {
      const newScreenType = getScreenType();
      if (newScreenType !== screenType) {
        imagesRef.current.forEach((img, index) => {
          if (img) {
            setFixedPosition(img, index);
          }
        });
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="result-banner">
      <div className="result-overlay"></div>
      <div
        className="result-heading"
        data-aos="zoom-in"
        data-aos-duration="1500"
      >
        <h1 className="banner-heading mb-4">Results speak</h1>
        <h5 className="banner-sub-heading ">
          Our Community Members have many stories to share
        </h5>
      </div>
      <div className="result-bg-images ">
        <img  loading="lazy" 
          className="result-image"
          src={rb1}
          alt=""
          ref={(el) => (imagesRef.current[0] = el)}
        />
        <img  loading="lazy" 
          className="result-image"
          src={rb2}
          alt=""
          ref={(el) => (imagesRef.current[1] = el)}
        />
        <img  loading="lazy" 
          className="result-image"
          src={rb3}
          alt=""
          ref={(el) => (imagesRef.current[2] = el)}
        />
        <img  loading="lazy" 
          className="result-image"
          src={rb4}
          alt=""
          ref={(el) => (imagesRef.current[3] = el)}
        />
        <img  loading="lazy" 
          className="result-image"
          src={rb5}
          alt=""
          ref={(el) => (imagesRef.current[4] = el)}
        />
        <img  loading="lazy" 
          className="result-image"
          src={rb6}
          alt=""
          ref={(el) => (imagesRef.current[5] = el)}
        />
        <img  loading="lazy" 
          className="result-image"
          src={rb7}
          alt=""
          ref={(el) => (imagesRef.current[6] = el)}
        />
        <img  loading="lazy" 
          className="result-image"
          src={rb8}
          alt=""
          ref={(el) => (imagesRef.current[7] = el)}
        />
        <img  loading="lazy" 
          className="result-image"
          src={rb9}
          alt=""
          ref={(el) => (imagesRef.current[8] = el)}
        />
        <img  loading="lazy" 
          className="result-image"
          src={rb10}
          alt=""
          ref={(el) => (imagesRef.current[9] = el)}
        />
        <img  loading="lazy" 
          className="result-image"
          src={rb11}
          alt=""
          ref={(el) => (imagesRef.current[10] = el)}
        />
        <img  loading="lazy" 
          className="result-image"
          src={rb12}
          alt=""
          ref={(el) => (imagesRef.current[11] = el)}
        />
        <img  loading="lazy" 
          className="result-image"
          src={rb13}
          alt=""
          ref={(el) => (imagesRef.current[12] = el)}
        />
        <img  loading="lazy" 
          className="result-image"
          src={rb14}
          alt=""
          ref={(el) => (imagesRef.current[13] = el)}
        />
        <img  loading="lazy" 
          className="result-image"
          src={rb15}
          alt=""
          ref={(el) => (imagesRef.current[14] = el)}
        />
        <img  loading="lazy" 
          className="result-image"
          src={rb16}
          alt=""
          ref={(el) => (imagesRef.current[15] = el)}
        />
        <img  loading="lazy" 
          className="result-image"
          src={rb17}
          alt=""
          ref={(el) => (imagesRef.current[16] = el)}
        />
        {/* <img  loading="lazy"  className='result-image' src={rb18} alt='' ref={el => imagesRef.current[17] = el} /> */}
        {/* <img  loading="lazy"  className='result-image' src={rb19} alt='' ref={el => imagesRef.current[18] = el} /> */}
        <img  loading="lazy" 
          className="result-image"
          src={rb20}
          alt=""
          ref={(el) => (imagesRef.current[17] = el)}
        />
        <img  loading="lazy" 
          className="result-image"
          src={rb21}
          alt=""
          ref={(el) => (imagesRef.current[18] = el)}
        />
        <img  loading="lazy" 
          className="result-image"
          src={rb22}
          alt=""
          ref={(el) => (imagesRef.current[19] = el)}
        />
        <img  loading="lazy" 
          className="result-image"
          src={rb23}
          alt=""
          ref={(el) => (imagesRef.current[20] = el)}
        />
      </div>
    </div>
  );
};

export default ResultsBanner;
