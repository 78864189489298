import React, { useEffect } from 'react'
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import ResultsBanner from '../Components/Results/ResultsBanner';
import ResultsContent from '../Components/Results/ResultsContent';
import FacebookPixel from '../Components/FacebookPixel';

const Results = () => {
    useEffect(() => {
        window.scroll(0, 0);
    }, []);
    return (
        <div>
            <FacebookPixel/>
            <Navbar />
            <ResultsBanner />
            <ResultsContent />
            <Footer />
        </div>
    )
}

export default Results