import React from "react";

import eb1 from "../../assets/images/experts/eb1.webp";
import eb2 from "../../assets/images/experts/eb2.webp";
import eb3 from "../../assets/images/experts/eb3.webp";
import eb4 from "../../assets/images/experts/eb4.webp";
import eb5 from "../../assets/images/experts/eb5.webp";
import eb6 from "../../assets/images/experts/eb6.webp";
import eb7 from "../../assets/images/experts/eb7.webp";

const ExpertsBanner = () => {
  return (
    <div className="expert-banner position-relative">
      <div className="expertbanner-content" style={{ position: "relative" }}>
        <h1
          className="banner-heading mb-4"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          Know our Digital Experts
        </h1>
        <h5
          className="banner-sub-heading mb-5"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          Who Practice what they Teach Everyday
        </h5>
      </div>

      {/* <img  loading="lazy"  src={exportbanimg} alt="../" className="expertimgban" /> */}
      <div
        id="carouselExampleControls"
        className="carousel carousel-banner-expert slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img  loading="lazy" 
              src={eb1}
              className="d-block w-100"
              style={{ borderRadius: "15px" }}
              alt="..."
            />
          </div>
          <div className="carousel-item">
            <img  loading="lazy" 
              src={eb2}
              className="d-block w-100"
              style={{ borderRadius: "15px" }}
              alt="..."
            />
          </div>
          <div className="carousel-item">
            <img  loading="lazy" 
              src={eb3}
              className="d-block w-100"
              style={{ borderRadius: "15px" }}
              alt="..."
            />
          </div>
          <div className="carousel-item">
            <img  loading="lazy" 
              src={eb4}
              className="d-block w-100"
              style={{ borderRadius: "15px" }}
              alt="..."
            />
          </div>
          <div className="carousel-item">
            <img  loading="lazy" 
              src={eb5}
              className="d-block w-100"
              style={{ borderRadius: "15px" }}
              alt="..."
            />
          </div>
          <div className="carousel-item">
            <img  loading="lazy" 
              src={eb6}
              className="d-block w-100"
              style={{ borderRadius: "15px" }}
              alt="..."
            />
          </div>
          <div className="carousel-item">
            <img  loading="lazy" 
              src={eb7}
              className="d-block w-100"
              style={{ borderRadius: "15px" }}
              alt="..."
            />
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default ExpertsBanner;
