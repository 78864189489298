import React, { useState } from 'react'
import Blog1 from '../../assets/images/blogs/blog1.webp'

const BlogsContent = () => {

    const blogTabs = ["Digital Marketing", "Sales", "Enterpreneurship", "Freelancing", "Tools & Tutorial", "Events & Convocation"];
    const [selectedTab, setSelectedTab] = useState("Digital Marketing");

    const digitalMarketing = [
        {
            img: require('../../assets/images/blogs/blog2.webp'),
            date: 'August 16, 2023',
            heading: 'Six Pillars of Digital Marketing',
            url: '/#/six-pillars-of-digital-marketing'
        },
        {
            img: require('../../assets/images/blogs/blog3.webp'),
            date: 'July 25, 2023',
            heading: 'How to start a Digital Marketing Agency',
            url: '/#/how-to-start-a-digital-marketing-agency',
        },
        {
            img: require('../../assets/images/blogs/blog4.webp'),
            date: 'July 24, 2023',
            heading: 'Top Digital Marketing Project Ideas (2023)',
            url: '/#/top-digital-marketing-project-ideas',
        },
        {
            img: require('../../assets/images/blogs/blog5.webp'),
            date: 'July 24, 2023',
            heading: 'Digital Marketing Course After 10th',
            url: '/#/digital-marketing-course-after-10th',
        },
        {
            img: require('../../assets/images/blogs/blog6.webp'),
            date: 'July 24, 2023',
            heading: 'MBA Marketing Project Topics',
            url: '/#/MBA-marketing-project-topics',
        },
        {
            img: require('../../assets/images/blogs/blog7.webp'),
            date: 'June 26, 2023',
            heading: 'How to become a Digital Marketing Specialist',
            url: '/#/how-to-become-a-digital-marketing-specialist',
        },
        {
            img: require('../../assets/images/blogs/blog8.webp'),
            date: 'June 24, 2023',
            heading: 'Types of Digital Marketing: A Comprehensive Guide',
            url: '/#/types-of-digital-marketing-a-comprehensive-guide',
        },
        {
            img: require('../../assets/images/blogs/blog9.webp'),
            date: 'June 22, 2023',
            heading: 'Digital Marketing Trends: Insights for Success',
            url: '/#/digital-marketing-trends',
        },
        {
            img: require('../../assets/images/blogs/blog10.webp'),
            date: 'June 20, 2023',
            heading: 'Scope of Digital Marketing',
            url: '/#/scope-of-digital-marketing',
        },
        {
            img: require('../../assets/images/blogs/blog11.webp'),
            date: 'June 18, 2023',
            heading: 'Digital Marketing Course After 12th',
            url: '/#/digital-marketing-coursea-fter-12th',
        },
        {
            img: require('../../assets/images/blogs/blog12.webp'),
            date: 'June 16, 2023',
            heading: 'Digital Marketing Jobs for freshers: Explore the Opportunity',
            url: '/#/digital-marketing-jobs-for-freshers',
        },
    ]
    return (
        <div className='blogs-content'>
            <div className='hands-on-section position-relative'>
                <div className='container py-5'>
                    <div className='blog-tab-section pb-2'>
                        {blogTabs.map((tab, index) => {
                            return (
                                <h5 key={index} onClick={() => setSelectedTab(tab)} className={`d-flex  ${selectedTab === tab ? "blogs-tab-content line-anim-result active" : "blogs-tab-content"}`}>
                                    {tab}
                                    {index !== blogTabs.length - 1 && <span className="border-end-blogs"></span>}
                                    </h5>
                            )
                        })}
                    </div>
                    {selectedTab === "Digital Marketing" && (
                        <div className='pt-5'>
                            <div className='container'>
                                <div className='bg-blog mb-4'>
                                    <div className='row'>
                                        <div className='col-lg-8'>
                                            <div className=''>
                                                <img className='blogs-img-top ' loading="lazy" src={Blog1} alt="" />
                                            </div>
                                        </div>
                                        <div className='col-lg-4 my-auto'>
                                            <div className=' pe-lg-0 pe-3 p-3'>
                                                <p className='date-blog'>August 25, 2023</p>
                                                <h5 className='blogs-heading'>The Complete Digital Marketing Course 12 – Courses in 1</h5>
                                                <p className='choose-us-para-text  '>Hello Digital enthusiasts! Are you excited to explore the amazing world of Internet marketing? Well, get ready, because we’re about to share some exciting information.</p>
                                                <a href="/#/the-complete-digital-marketing-course" className='read-more-text'>Read More...</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    {digitalMarketing.map((item, index) => {
                                        return (
                                            <div className='col-lg-4 mb-4' key={index}>
                                                <div className='bg-blog '>
                                                    <img className='blogs-img' loading="lazy" src={item.img} alt="" />
                                                    <div className='padding-blogs'>
                                                        <p className='date-blog'> {item.date}</p>
                                                        <h5 className='blogs-heading'>{item.heading}</h5>
                                                        <a href={item.url} className='read-more-text'>Read More...</a>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>


                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div>
    )
}

export default BlogsContent