import React from 'react'

const BlogsBanner = () => {
    return (
        <div className="about-banner">
            <div className="mt-lg-0 mt-md-0 mt-5 ">
                <h1
                    className="banner-heading"
                    data-aos="zoom-in"
                    data-aos-duration="1500"
                >
                    Blogs
                </h1>
            </div>
        </div>
    )
}

export default BlogsBanner