import React, { useState, useRef, useEffect } from "react";
import "@wistia/wistia-player";
import VideoThumbnail from "../../assets/images/home-video-thumbnail.webp";
import PlayIcon from "../../assets/images/play-icon.webp";
import PlayIconTesti from "../../assets/images/play-icon-testi.webp";
import DownArrow from "../../assets/images/arrow-down.webp";
import HomeAward from "../../assets/images/home-award.webp";
import TeamHome from "../../assets/images/team-home.webp";
import Favicon from "../../assets/images/logo-fav.webp";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import CustomModal from "../Model/CustomModal";
export const HomeContent = () => {
  const [openVideo, setOpenVideo] = useState(false);
  const [currentMediaId, setCurrentMediaId] = useState(null);

  const [isPlaying, setIsPlaying] = useState(false);

  const [activeTab, setActiveTab] = useState(0);
  const videoRefs = useRef([]);
  const observerRef = useRef(null);

  // Set up Intersection Observer to detect which video is in view
  useEffect(() => {
    if (observerRef.current) observerRef.current.disconnect();

    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = videoRefs.current.indexOf(entry.target);
            if (index !== -1) {
              setActiveTab(index);
            }
          }
        });
      },
      { threshold: 0.5 }
    ); // Adjust threshold as needed

    videoRefs.current.forEach((video) => {
      if (video) observerRef.current.observe(video);
    });

    return () => {
      if (observerRef.current) observerRef.current.disconnect();
    };
  }, []);

  const handleTabClick = (index) => {
    setActiveTab(index);
    if (videoRefs.current[index]) {
      const offsetTop =
        videoRefs.current[index].getBoundingClientRect().top +
        window.pageYOffset -
        100; // Adjust -100 to your desired offset
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };

  const location = useLocation();
  const navigation = useNavigate();
  useEffect(() => {
    const { search } = location;
    const containerId = search.substring(4); // Remove the leading slash
    const element = document.getElementById(containerId);

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "center",
      });
    }
  }, [location]);

  const handsOnContent = [
    {
      mediaId: "jvoku2s6zj",
      mediaPara:
        "Emphasis on Entrepreneurship Training as success lies in understanding the Business fully and totally",
      thumbImg: require("../../assets/images/home-course/entrepreneurship.webp"),
    },
    {
      mediaId: "k5t6pqrogx",
      mediaPara:
        "Tested Knowledge & Market-Ready Training which can be implemented right away",
      thumbImg: require("../../assets/images/home-course/knowledge.webp"),
    },
    {
      mediaId: "khrd0mmti8",
      mediaPara:
        "Data-Driven, Customer-Centric, Agency-Based, Performance-Oriented Strategies & Training",
      thumbImg: require("../../assets/images/home-course/training.webp"),
    },
    {
      mediaId: "cdrbmupecx",
      mediaPara:
        "Blended Model with a Practical and End-to-End Training Approach",
      thumbImg: require("../../assets/images/home-course/methodology.webp"),
    },
    {
      mediaId: "mztjxl0bsh",
      mediaPara:
        "A Community of Positive like-minded individuals where one can collaborate, hire, outsource and build together with a win-win mindset",
      thumbImg: require("../../assets/images/home-course/community.webp"),
    },
    {
      mediaId: "k6udl6kag8",
      mediaPara:
        "Continuous & Extended Knowledge Transfer even after course completion so students can be self-reliant",
      thumbImg: require("../../assets/images/home-course/continuous-learning.webp"),
    },
    {
      mediaId: "onxfyjwgl6",
      mediaPara:
        "Taught by Who’s Who of the Industry, Complete Experts in their Domain Experience",
      thumbImg: require("../../assets/images/home-course/instructors.webp"),
    },
    {
      mediaId: "78cmi5vrkw",
      mediaPara:
        "Complete Training from understanding the business to getting & working with clients and scaling the business",
      thumbImg: require("../../assets/images/home-course/business-101.webp"),
    },
    {
      mediaId: "whlsr6kkmp",
      mediaPara:
        "Helping Members visualise the bigger opportunities through showing real-world scenarios",
      thumbImg: require("../../assets/images/home-course/resources.webp"),
    },
    {
      mediaId: "gnr0eeig7g",
      mediaPara:
        "Dedicated Guidance through Action-Oriented Implementable Frameworks, along with peer-to-peer communication and support",
      thumbImg: require("../../assets/images/home-course/mentorship.webp"),
    },
  ];

  const [isPlaying1, setIsPlaying1] = useState(
    Array(handsOnContent.length).fill(false)
  );
  const handlePlay = (index) => {
    setIsPlaying1((prevState) => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });

    setTimeout(() => {
      const videoContainer = videoRefs.current[index];
      if (videoContainer) {
        // Remove the existing thumbnail content
        videoContainer.innerHTML = "";

        // Create a new div for the Wistia player
        const wistiaDiv = document.createElement("div");
        wistiaDiv.className = `wistia_embed wistia_async_${handsOnContent[index].mediaId} playerColor=#D32B3A`;
        wistiaDiv.style.width = "100%";
        wistiaDiv.style.height = "450px"; // Default height for larger screens
        wistiaDiv.style.borderRadius = "12px";

        // Append the Wistia player div to the container
        videoContainer.appendChild(wistiaDiv);

        // Initialize the Wistia player
        window._wq = window._wq || [];
        window._wq.push({
          id: handsOnContent[index].mediaId,
          onReady: function (video) {
            video.play();
          },
        });

        // Apply media queries for responsive design
        const style = document.createElement("style");
        style.innerHTML = `
                    @media (max-width: 768px) {
                        .wistia_embed {
                            height: 198px !important;
                        }
                    }
                `;
        document.head.appendChild(style);
      }
    }, 0);
  };

  const videos = [
    {
      thumbImg: require("../../assets/images/result-testi/1.SEA Thumbnail Ganesh.webp"),
      mediaId: "7u2k1g6s96",
      heading:
        "How Ganesh was able to make 26 Lakh in Profit with 1000 Rupees.",
      para: "Ganesh, a real estate business owner frustrated with unreliable agencies, self-learned digital marketing but struggled with the terminology. Joining Social Eagle Academy resolved his doubts in a month. He ran successful ads, earning 26 lakhs from one sale. He then launched his agency and became a digital strategist.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/2.SEA Thumbnail Richerd.webp"),
      mediaId: "abdz3imvg1",
      heading:
        "Richerd Made over 23 Lakh and also increased this Team Size by 3X.",
      para: "Richard, a digital marketer and business owner, faced financial struggles and lost confidence. Joining Social Eagle’s program connected him with supportive peers, boosting his motivation. He earned over 20 lakhs and won the Eagle Award at the TN Digital Summit.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/3.SEA Thumbnail Maheswari.webp"),
      mediaId: "2ofw3e9nox",
      heading:
        "How Maheswari Made Over 20 Lakhs in Revenue by Leveraging Digital Marketing in 3 Months.",
      para: "Maheswari runs Osai Media, a media company. Despite having her own agency, she wanted to upskill and help her clients transition to digital marketing. After joining Social Eagle’s Mentorship Program, she started upselling digital services and onboarding new clients. Using her new skills in other businesses, she comfortably made over 16 lakhs in 3 months.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/4.SEA Thumbnail Kamalakannan.webp"),
      mediaId: "rsdlzuxwhn",
      heading:
        "How Kamal turned into an Entrepreneur from an Employee while making more than 20 Lakhs in revenue.",
      para: "Kamal was a Corporate Employee and was helping his wife’s small business. He wanted to learn Digital Marketing to help his wife and in the process he started his own agency. He built Social Guru and now it is one of the renowned agencies in Dindigul, Tamil Nadu.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/5.SEA Thumbnail Mark Marimuthu.webp"),
      mediaId: "pyt49lf1lg",
      heading:
        "How Mark Marimuthu Made Over 20 Lakhs by Integrating Digital Marketing into His Existing Business.",
      para: "Mark Marimuthu runs a traditional media business. He wanted to add digital marketing services, so he joined Social Eagle’s Mentorship Program. By upselling digital marketing to his clients and offering combined services, he earned over 20 lakhs in less than 5 months.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/6.SEA Thumbnail Ameenur.webp"),
      mediaId: "xagsewhadg",
      heading:
        "How Ameenur Made Over 20 Lakhs in Revenue from Part-time Work in His Business.",
      para: "Ameenur, a full-time employee in Germany, runs two part-time businesses. After struggling despite joining many courses, he joined Social Eagle’s Digital Entrepreneurship Program, found the missing link, and now earns 2-3 lakhs every two weeks through webinars.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/7.SEA Thumbnail Ramesh.webp"),
      mediaId: "onc6flwghu",
      heading:
        "How Ramesh was able to get 4 New Clients Every Month for his agency services.",
      para: "Ramesh quickly shifted from being a professor to owning an agency within just 40 days of starting to learn Digital Marketing. Mastering everything from strategy creation to high-ticket sales boosted his confidence, securing him four clients monthly. He's now expanding his team.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/8.SEA Thumbnail Mukesh.webp"),
      mediaId: "uj51xtbu0a",
      heading: "How Mukesh bought his first car in 90 daysThrough Freelancing.",
      para: " Having spent more than 11L rupees in various other courses, Mukesh was not able to find the depth of digital marketing until he found the right knowledge and mentors at Social Eagle",
    },
    {
      thumbImg: require("../../assets/images/result-testi/9.SEA Thumbnail Alex Vibin.webp"),
      mediaId: "rfd2t49pg0",
      heading:
        "How Alex Vibin Grew His E-Commerce Brand and Started Two Other Businesses, Earning Over 10 Lakhs",
      para: "Alex Vibin, founder of Noobie Kid, an e-commerce brand selling kids toys, struggled with consistent sales through organic content. After joining the Digital Entrepreneurship Program, he started running ads, doubling his sales predictably. He also started a digital marketing agency and a tourism company, leveraging digital marketing to succeed.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/10.SEA Thumbnail Navaneetha.webp"),
      mediaId: "x1ybdl8ij9",
      heading:
        "How Navaneetha made more than 10 Lakhs after a Comeback from Career Break!",
      para: "Navaneetha, a housewife living in Saudi Arabia, began learning digital marketing to do something after a Career Break. After a 30-day course, she started her own Marketing agency, helping brands build authority on Social Media. Now, she has made more than 10 lakhs and chooses her clients. Her Success lies in her Collaborating power with the talents inside and outside the community.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/11.SEA Thumbnail Ragothaman.webp"),
      mediaId: "hjworhiwhn",
      heading:
        "How Ragothaman made more than 10 Lakhs in Revenue for his Real Estate Business!.",
      para: "Ragothaman, who runs a real estate business in Chennai, was frustrated with not getting qualified leads despite paying for third-party platforms. He decided to learn digital marketing to generate his own leads. After completing the course, he successfully generated leads from his ads and earned over 10 lakhs in revenue. Now, he’s confident and is scaling his business.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/37.SEA Thumbnail Abirami.webp"),
      mediaId: "rr6nu1fqui",
      heading: "How Abirami Became an Entrepreneur instead of an Employee",
      para: " Abirami became a housewife after moving to Dubai with her husband, putting her career dreams on hold. With 5 years of SEO experience, she wanted to restart her career but lacked a clear path. She joined Social Eagle Academy’s Digital Entrepreneurship Course, initially planning to find a job afterward. However, within 30 days, she started her own business and is now making five figures in revenue.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/13.SEA Thumbnail Naveen.webp"),
      mediaId: "oq7cthiyw7",
      heading:
        "How Naveen was able to make More than 7 Lakhs as an agency owner after failing in his 3 other businesses.",
      para: "Naveen, a first-time entrepreneur and mechanical engineer, had several failed businesses before joining Social Eagle. Now, after completing the Digital Entrepreneurship Initiator Program, he successfully runs a five-figure agency alongside his engineering career.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/14.SEA Thumbnail Priya.webp"),
      mediaId: "4zd36w6ie3",
      heading:
        "How a Corporate Employee like Priya was able to make more  than 5 Lakh with her side business using Digital Marketing",
      para: "Priya, originally an employee, joined Social Eagle Academy to learn digital marketing and assist her company. Discovering her passion, she began side hustles alongside her job, earning over 5 lakhs in five months.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/15.SEA Thumbnail Jeyasudha.webp"),
      mediaId: "80z8jx9epp",
      heading: "How Jeyasudha was able to earn 6+ Lakhs as an housewife.",
      para: "Jeyasudha, a former employee on a career break post-marriage, sought work-from-home opportunities. Discovering Social Eagle Academy's Digital Entrepreneurship Initiator Course, she secured her first client in under 30 days and now serves over 5 clients, earning over 6 lakh in revenue within 3 months.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/16.SEA Thumbnail Anitha.webp"),
      mediaId: "lju3ufacoo",
      heading: "How Anitha Made Over 5 Lakhs with Digital Marketing",
      para: "Anitha, a housewife living in Dubai, began learning digital marketing to help her husband's business. After a 30-day course, she started her own content marketing agency, helping brands build authority on LinkedIn. Now, she earns over 5 lakhs and chooses her clients.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/17.SEA Thumbnail Sunitha.webp"),
      mediaId: "k7msiy3rrb",
      heading: "How Sunitha Made Over 5 Lakhs in 60 Days",
      para: "Sunitha, a PhD scholar and healthcare consultant, was about to join a year-long digital marketing course. Instead, she joined Social Eagle after learning about Dharaneetharan and his team. Implementing what she learned and earning additional certifications, she became a LinkedIn Personal Branding Expert. Serving clients, she made over 5 lakhs in less than 60 days.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/18.SEA Thumbnail Vinothini.webp"),
      mediaId: "d6jzrvsx48",
      heading:
        "How Vinothini was able to grow her offline Boutique’s sales by 3X during off-season.",
      para: "Vinothini runs a boutique for kids party wear online and offline, a unique niche. After taking multiple courses with mixed results, she found success with mentorship from Social Eagle Academy. Implementing her new knowledge, she received over 1000 inquiries in the first week and made sales exceeding 8 lakhs within 30 days.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/19.SEA Thumbnail Giriesh.webp"),
      mediaId: "u6nxrhdu7i",
      heading:
        "How Giriesh helped his client make over $3000 with an investment of 1000 rupees.",
      para: "Giriesh, being a digital marketer was able to narrow his interest into funnels and email marketing while working with his clients. Now he is helping his e-com clients make $600 - $1000. He got this direction after he found the right knowledge and mentors at Social Eagle.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/20.SEA Thumbnail Karthik Raja.webp"),
      mediaId: "dqjap5tt8n",
      heading:
        "How Karthik Raja Earned more than 7 Lakhs without any Tech Experience",
      para: " Karthik runs a small business in southern Tamil Nadu with no prior tech experience. After learning digital marketing from Social Eagle Academy, he successfully generated leads for his real estate business. Interestingly, he began offering lead generation services to other businesses, starting his own agency and earning over 7 lakhs.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/21.SEA Thumbnail Sanjay.webp"),
      mediaId: "4l6znlv60g",
      heading:
        "How Sanjay Earns an Extra 1 Lakh as a Freelancer While Working Full-Time",
      para: "Sanjay is a digital marketing executive. His full-time job doesn't pay enough. He joined to earn an extra 1 lakh rupees as a freelancer. By using the Community's support, he reached his goal in less than 20 days after joining the Digital Entrepreneurship Program.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/22.SEA Thumbnail Jamuna.webp"),
      mediaId: "ioem04k50a",
      heading:
        "How Jamuna is making a profit of over 1 Lakh rupees for her Gift Shop Business.",
      para: "Jamuna was struggling to find a way to promote her offline gift shop in online. She tried multiple ways which she found was not helpful until she joined the Digital Entrepreneurship Initiator Program at Social Eagle.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/23.SEA Thumbnail Muthu Kumar.webp"),
      mediaId: "asoipzigg3",
      heading:
        "How Muthu Kumar added Digital Marketing to His Consulting Business and Earns Over 1 Lakh Each",
      para: "MonthMuthu Kumar runs a manufacturing consulting business, training people on processes and sales. After mentorship from Social Eagle Academy, he added digital marketing services to his offerings. Now, he earns an extra 1 lakh every month alongside his consulting business.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/24.SEA Thumbnail Kannan.webp"),
      mediaId: "h16jza2k15",
      heading:
        "How Kannan Increased His Client Base by 55% in less than 20 Days and is earning 4L/month",
      para: "Kannan, founder of Growpix, a digital marketing agency, wanted to grow his business beyond 10 clients. After joining the Digital Entrepreneurship Program, he helped clients earn over 35 lakhs in 40 days. This success led to many referrals, increasing his client base by 55% in less than 20 days.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/25.SEA Thumbnail Santhosh.webp"),
      mediaId: "b1jfys9j0q",
      heading: "How Santhosh made 36 times Returns for his Business.",
      para: "Santhosh, who runs three different businesses, wanted to learn digital marketing to better understand his team's work. Interestingly, he now runs his own campaigns and manages his team more effectively. His efforts have resulted in a 36x return on investment for his businesses.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/26.SEA Thumbnail Rajeswari.webp"),
      mediaId: "al5dy58ox4",
      heading:
        "Rajeswari earned over 3.8 Lakhs and Recreated her Identity after a Career Break",
      para: "Rajeswari, a housewife and former HR professional, took a career break after marriage but wanted to become independent again. She joined Social Eagle’s Digital Marketing Course and began by training others in digital marketing. She then started helping small businesses increase visibility and profits through her digital marketing services.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/27.SEA Thumbnail Shamrin.webp"),
      mediaId: "jb1ka033to",
      heading:
        "How Shamrin was able to make more than 2.6 Lakhs in 2 months with Digital Marketing!",
      para: "Shamrin wanted to help her husband’s printing business, so she started learning digital marketing. After completing the course, she launched her own agency. One of her clients even bought her a complete computer setup, and she used her earnings to start a physical office. She has made over 2.6 lakhs through digital marketing.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/28.SEA Thumbnail Jahir.webp"),
      mediaId: "vnbqwt8bko",
      heading: "Jahir earned over 2.4 Lakhs as an Extra Income.",
      para: " Jahir, a public speaking trainer, wanted to learn digital marketing because his friends were talking about it. He chose Social Eagle for his learning journey. While studying, he secured three speaking assignments and now uses digital marketing to grow his training business. Additionally, he helps other businesses generate leads, earning an extra 60K per month and has made over 2.4 lakhs so far.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/29.SEA Thumbnail Senthil Kumar.webp"),
      mediaId: "1c27fxuqjx",
      heading:
        "Senthil Kumar made over 2.3 Lakhs by providing Digital Marketing Services",
      para: "Senthil Kumar wanted to learn digital marketing to grow his medical equipment business. After completing the course, he used digital marketing to expand his business and also started his own agency. Through his agency, he made over 2.3 lakhs and even secured a deal with a government agency.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/30.SEA Thumbnail Ram Kumar.webp"),
      mediaId: "yuonw7a0su",
      heading: "How Ram Kumar Landed Big Clients billing Over 2 Lakhs",
      para: "Ram Kumar wanted to leave his current job. After joining Social Eagle Academy’s Mentorship Program and applying what he learned, he secured a major client deal worth over 7 lakhs in less than 30 days. Now, he is happily working as a digital entrepreneur.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/31.SEA Thumbnail Ashwin Krishna.webp"),
      mediaId: "q1htm1eraw",
      heading:
        "Ashwin Krishna made more than 5X Return on Investment in 60 days",
      para: "Ashwin, who runs a trading academy, was already using digital marketing but wanted to scale more efficiently. After joining Social Eagle, he began generating high-quality leads and conversions for his academy. Now, he also works with leading content creators and educators in the region, helping them with lead generation and sales.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/32.SEA Thumbnail Pavithra.webp"),
      mediaId: "71nqsiljho",
      heading:
        "Pavithra made 2 times the returns through collaboration inside the community",
      para: "Pavithra, a housewife and graphic designer helping her father’s printing business, wanted to recreate her identity and make a career comeback. After trying various approaches without success, she began collaborating with the Social Eagle Academy community. In less than 6 months, she earned over twice her investment. Her grit and determination were key to her success.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/33.SEA Thumbnail Kishore.webp"),
      mediaId: "1padnfbixe",
      heading:
        "Kishore earned the Respect he always wanted from his loved ones by making 1L+ in 30 days",
      para: "Kishore, a graduate helping with his father’s business, felt unfulfilled and lacked respect at home and among friends. Determined to make a name for himself, he joined Social Eagle Academy’s Digital Marketing Course. In less than 30 days, he earned over 1 lakh rupees and gained the respect he had longed for from his loved ones.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/34.SEA Thumbnail Selva Mariammal.webp"),
      mediaId: "w4y0kj361h",
      heading: "Selva Mariammal Made over 1.5 Lakh while working from home.",
      para: "Selva Mariammal, a housewife, wanted to work from home while spending time with her kids. With a passion for design, she decided to learn digital design and joined Social Eagle. After completing the course, she interned with entrepreneurs from the community, gaining real-time, practical experience. She then landed an opportunity to work with a renowned brand, allowing her to work from home and still be with her kids. She has made over 1.5 lakhs so far while working from home.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/35.SEA Thumbnail Jessy Priya.webp"),
      mediaId: "fks3f7kx3x",
      heading:
        "Jessy Priya Became the Family’s Breadwinner after marriage while earning over 1 Lakh",
      para: "Jessy Priya, a housewife, became a digital marketer after joining Social Eagle’s course. She started small, collaborating within the community, and used a 'value first, get paid later' strategy. This approach helped her earn more than she had invested, and she has now become the primary breadwinner of her family while earning over 1 Lakhs in revenue.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/36.SEA Thumbnail Selva Basti.webp"),
      mediaId: "hbsubfob8q",
      heading:
        "How Selva Basti earned 4 times more than his Salary in 2 Months!",
      para: "Selva Basti, a salaried employee, joined Social Eagle’s Digital Marketing Course to learn a new skill. By the end of the course, he joined networking forums, began offering digital marketing training, and gained clients. In less than 2 months, he earned 4 times more than his previous salary.",
    },
  ];

  const handleOpenMedia = (media) => {
    console.log("clicked");
    setOpenVideo(true);
    setCurrentMediaId(media);
  };
  return (
    <div className="home-content-section">
      <div className="home-video-section  overflow-hidden py-5">
        <div className="container pt-5 ">
          <div className="row justify-content-between">
            <div
              className="col-lg-8"
              data-aos="fade-up-right"
              data-aos-duration="1500"
            >
              <div className="position-relative">
                {!isPlaying ? (
                  <div
                    onClick={() => setIsPlaying(true)}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      width="272" height="272"
                      src={VideoThumbnail}
                      alt="Thumbnail"
                      loading="lazy"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "8px",
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <img
                        width="272" height="272"
                        className="play-icon-video "
                        loading="lazy"
                        src={PlayIcon}
                        alt=""
                      ></img>
                    </div>
                  </div>
                ) : (
                  <wistia-player
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "12px",
                    }}
                    media-id="h0i55nsez3"
                    player-color="#D32B3A"
                  ></wistia-player>
                )}
              </div>
            </div>
            <div
              className="col-lg-3"
              data-aos="fade-up-left"
              data-aos-duration="1500"
            >
              <div>
                <p className="home-video-para">
                  <span className="span-red-content">
                    Tamil Nadu’s only Digital Entrepreneurship Program&nbsp;
                  </span>
                  <br className="break-tag" />
                  Focused on Data-Driven,
                  <br className="break-tag" /> Performance-Based,
                  <br className="break-tag" /> Customer-Obsessed, and
                  <br className="break-tag" /> Result-Oriented learning
                  <br className="break-tag" /> models.
                </p>
                <div className="position-relative">
                  <NavLink to={"/?id=home-below-section"} class="circle-div">
                    <div class="circle-center-link">
                      <div class="circle-center">
                        <img
                          width="96" height="96"
                          className="down-arrow-rotate"
                          loading="lazy"
                          src={DownArrow}
                          alt="Tick"
                        />
                      </div>
                      <div id="circle" class="rotate">
                        <svg width="300px" height="300px" viewBox="0 0 300 300">
                          <defs>
                            <path
                              id="circlePath"
                              d="M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 "
                            ></path>
                          </defs>
                          <circle cx="150" cy="150" r="60" fill="none"></circle>
                          <g>
                            <use xlinkHref="#circlePath" fill="none"></use>
                            <text
                              fill="#fff"
                              font-size="10.5"
                              fontWeight="normal   "
                            >
                              <textPath xlinkHref="#circlePath">
                                &nbsp; &#183; &nbsp; FLY &nbsp; HIGH &nbsp; WITH
                                &nbsp; SOCIAL &nbsp; EAGLE &nbsp; &#183; &nbsp;
                                FLY &nbsp; HIGH &nbsp; WITH &nbsp; SOCIAL &nbsp;
                                EAGLE
                              </textPath>
                            </text>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="home-end-to-end-team-sections py-5 overflow-hidden"
        id="home-below-section"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="">
                <h5
                  className="left-para-subContent arial-bold"
                  data-aos="fade-up-right"
                  data-aos-duration="1500"
                >
                  Complete End-to-End Digital Marketing
                  <br className="break-tag" /> Training which is Functional, AI
                  Powered,
                  <br className="break-tag" /> 100% Hands-on, Agency-Based &{" "}
                  <br className="break-tag" />
                  supported by Who’s Who of the Industry
                </h5>
                <div
                  className="position-relative mt-lg-5 mt-md-5 mt-0 width-left-circle-content"
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                >
                  <div class="circle-div">
                    <a href="#" class="circle-center-link">
                      <div class="circle-center-left">
                        <img
                          width="100" height="100"
                          className="circle-center-rotate-img"
                          loading="lazy"
                          src={Favicon}
                          alt="Tick"
                        />
                      </div>
                      <div id="circle" className="rotate">
                        <svg width="300px" height="300px" viewBox="0 0 300 300">
                          <defs>
                            <path
                              id="circlePath"
                              d="M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0"
                            ></path>
                          </defs>
                          <circle cx="150" cy="150" r="60" fill="none"></circle>
                          <g>
                            <use href="#circlePath" fill="none"></use>
                            <text fill="#fff" fontSize="11" fontWeight="bold">
                              <textPath href="#circlePath">
                                &nbsp; &#183; &nbsp; 10 &nbsp; YEARS &nbsp; OF
                                &nbsp; SOCIAL &nbsp; EAGLE &nbsp; &#183; &nbsp;
                                10 &nbsp; YEARS &nbsp; OF &nbsp; SOCIAL &nbsp;
                                EAGLE &nbsp;
                              </textPath>
                            </text>
                          </g>
                        </svg>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div data-aos="fade-up-left" data-aos-duration="1500">
                <img width="217" height="211" className="image-sections" loading="lazy" src={TeamHome} alt=""></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-choose-us-section overflow-hidden py-5">
        <div className="container">
          <div className="section-handson-sticky">
            <h2
              className="section-heading mb-5"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              Why People Choose Us?
            </h2>
          </div>
          <div className="row justify-content-center py-3">
            <div className="col-lg-6">
              <div className="" data-aos="fade-right" data-aos-duration="1500">
                <h5 className="choose-us-sub-heading">Agency-Based Training</h5>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="" data-aos="fade-left" data-aos-duration="1500">
                <p className="choose-us-para-text">
                  From an Agency which has made Businesses over{" "}
                  <br className="break-tag" /> 100 crores in Revenue
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className="row justify-content-center py-3">
            <div className="col-lg-6">
              <div className="" data-aos="fade-right" data-aos-duration="1500">
                <h5 className="choose-us-sub-heading">
                  Year Long Support + Active Community
                </h5>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="" data-aos="fade-left" data-aos-duration="1500">
                <p className="choose-us-para-text">
                  52 Doubt Clearing Sessions
                  <br className="break-tag" /> Monthly Superstar Sessions +
                  Monthly Book Sessions
                </p>
                <p className="choose-us-para-text">
                  1000+ Positive Like-Minded People & Growing
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className="row justify-content-center py-3">
            <div className="col-lg-6">
              <div className="" data-aos="fade-right" data-aos-duration="1500">
                <h5 className="choose-us-sub-heading">
                  Entrepreneurship Skillset + <br className="break-tag" />
                  Mindset Training{" "}
                </h5>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="" data-aos="fade-left" data-aos-duration="1500">
                <p className="choose-us-para-text">
                  Through Online + Offline Sessions
                </p>
                <p className="choose-us-para-text">
                  Market-Ready Knowledge which works right now
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hands-on-section py-5">
        <div className="container">
          <div
            className="section-handson-sticky overflow-hidden"
            data-aos="zoom-in"
            data-aos-duration="1500"
          >
            <h2 className="section-heading">
              Hands-on. Disruptive. Result-Oriented.
            </h2>
            <h5 className="section-sub-heading mb-5">
              See how we teach our courses.
            </h5>
          </div>
          <div className="row">
            <div className="col-lg-3 position-relative">
              <div className="hands-on-left-content">
                <ul className="list-unstyled hands-on-content-mob pl-0">
                  {[
                    "Entrepreneurship",
                    "Knowledge",
                    "Training",
                    "Methodology",
                    "Community",
                    "Continuous Learning",
                    "Instructors",
                    "Business 101",
                    "Resources",
                    "Mentorship",
                  ].map((text, index) => (
                    <li key={index}>
                      <span
                        className={`hands-on-left-text ${activeTab === index ? "active" : ""
                          }`}
                        onClick={() => handleTabClick(index)}
                        ref={(el) => (videoRefs.current[index] = el)}
                      >
                        {text}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="hands-on-right-section">
                {handsOnContent.map((item, index) => (
                  <div
                    key={index}
                    className="hands-on-right-video position-relative"
                    ref={(el) => (videoRefs.current[index] = el)}
                    style={{ marginBottom: "16px" }}
                  >
                    {!isPlaying1[index] ? (
                      <div
                        onClick={() => handlePlay(index)}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={item.thumbImg}
                          loading="lazy"
                          alt="Thumbnail"
                          width="1280" height="720"
                          className="course-videos"
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          <img
                            width="272" height="272"
                            className="play-icon-video "
                            loading="lazy"
                            src={PlayIcon}
                            alt=""
                          ></img>
                        </div>
                      </div>
                    ) : (
                      <div
                        ref={(el) => (videoRefs.current[index] = el)}
                        style={{
                          width: "100%",
                          height: "450px",
                          borderRadius: "12px",
                        }}
                      ></div>
                    )}
                    <p className="right-side-video-content-para">
                      {item.mediaPara}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-student-says-section overflow-hidden py-5">
        <div className="container mb-lg-0 mb-md-0 mb-5">
          <div className="section-handson-sticky">
            <h2
              className="section-heading mb-5"
              data-aos="zoom-in"
              data-aos-duration="2500"
            >
              What Our Students Say
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="" data-aos="fade-up" data-aos-duration="1500">
                <OwlCarousel
                  className="owl-theme"
                  loop
                  autoplay={true}
                  items="1"
                  dots={false}
                  smartSpeed={2000}
                  autoplayHoverPause={true}
                  nav={true}
                  margin={20}
                  center={true}
                  autoplayTimeout={2000}
                  mouseDrag={false} // Disable mouse dragging
                  touchDrag={false}
                  responsive={{
                    360: {
                      items: "1",
                    },
                    414: {
                      items: "1",
                    },
                    670: {
                      items: "2",
                    },
                    992: {
                      items: "2",
                    },
                    1200: {
                      items: "3",
                    },
                  }}
                >
                  {videos.map((item, index) => {
                    return (
                      <div className="students-testi-card" key={index}>
                        <div className="">
                          <div className="position-relative">
                            <div onClick={() => handleOpenMedia(item.mediaId)}>
                              <img
                                width="1280" height="720"
                                className="stud-testi-card-img-home"
                                loading="lazy"
                                src={item.thumbImg}
                                alt="Thumbnail"
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: "5%",
                                  left: "3%",
                                }}
                              >
                                <img
                                  width="128" height="128"
                                  className="play-icon-video-testi pulse-button"
                                  loading="lazy"
                                  src={PlayIconTesti}
                                  alt=""
                                ></img>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="students-testi-card-content">
                          <h5 className="students-testi-heading">
                            {item.heading}
                          </h5>
                          <p className="mb-0 students-testi-para">
                            {item.para}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Home-award-section  overflow-hidden py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="row justify-content-between">
                <div className="col-lg-6">
                  <div
                    className="home-award-left-img-section"
                    data-aos="zoom-in"
                    data-aos-duration="2500"
                  >
                    <img
                      width="639" height="992"
                      className="home-award-img"
                      loading="lazy"
                      src={HomeAward}
                      alt=""
                    ></img>
                  </div>
                </div>
                <div className="col-lg-5 my-auto">
                  <div
                    className="eagle-award-home-content"
                    data-aos="fade-left"
                    data-aos-duration="2500"
                  >
                    <h1 className="eagle-award-home-left-content">
                      Become a <br className="break-tag" />
                      Part of our <br className="break-tag" />
                      Community <br className="break-tag" />
                      & Win the <br className="break-tag" />
                      Prestigious <br className="break-tag" />
                      <span className="eagle-award-gradient">Eagle Award</span>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="results-content">
        <CustomModal
          open={openVideo}
          onClickOutside={() => {
            setOpenVideo(false);
            setCurrentMediaId(null);
          }}
        >
          <div className="modal-content contact-container m-auto">
            <div className="modal-body w-100 p-0 ">
              <div className="close-btn">
                <i
                  className="fa-regular fa-circle-xmark"
                  onClick={() => setOpenVideo(false)}
                ></i>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="">
                      <wistia-player
                        style={{
                          width: "100%",
                          height: 420,
                          borderRadius: 12,
                        }}
                        media-id={currentMediaId}
                        player-color="#D32B3A"
                      ></wistia-player>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CustomModal>
      </div>
    </div>
  );
};
