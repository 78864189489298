import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSpinner } from "@fortawesome/free-solid-svg-icons";
import downleftpic from "../../assets/images/downleftpic.webp";
import axios from "axios";
import BrochureDownloadModal from "../Model/BroucherDownloadModel";
import ThankYouModel from "../Model/ThankYouModel";

const ContactBanner = () => {
  const [formdata, setFormdata] = useState({
    fullname: "",
    whatsappnumber: "",
    email: "",
    city: "",
    designation: "",
  });
  const [open, setOpen] = useState(false);
  const [thankYouModal, setThankYouModal] = useState(false);
  const [validationError, setValidationError] = useState({});
  const [loading, setLoading] = useState(false);

  const handlechange = (e) => {
    const { name, value } = e.target;
    setFormdata({ ...formdata, [name]: value });
  };

  const validate = () => {
    let error = {};
    const nameRegex = /^[A-Za-z\s]+$/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Fullname validation
    if (formdata.fullname.trim() === "") {
      error.fullname = "Full Name cannot be empty.";
    } else if (formdata.fullname.length < 3) {
      error.fullname = "Full Name must be at least 3 characters long.";
    } else if (!nameRegex.test(formdata.fullname)) {
      error.fullname = "Full Name can only contain letters and spaces.";
    }

    // WhatsApp number validation
    if (formdata.whatsappnumber.trim() === "") {
      error.whatsappnumber = "WhatsApp number cannot be empty.";
    } else if (!/^[6-9]/.test(formdata.whatsappnumber)) {
      error.whatsappnumber = "Invalid WhatsApp numbera";
    } else if (!/^\d{10}$/.test(formdata.whatsappnumber)) {
      error.whatsappnumber = "WhatsApp number must be exactly 10 digits.";
    }

    // Email validation
    if (formdata.email.trim() === "") {
      error.email = "Email cannot be empty.";
    } else if (!emailPattern.test(formdata.email)) {
      error.email = "Invalid email address.";
    }

    // City validation
    if (formdata.city.trim() === "") {
      error.city = "City cannot be empty.";
    } else if (!nameRegex.test(formdata.city)) {
      error.city = "City can only contain letters and spaces.";
    }

    // Designation validation
    if (!formdata.designation.trim()) {
      error.designation = "Designation is required.";
    }

    return error;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    setValidationError(errors);

    if (Object.keys(errors).length === 0) {
      let inputvalue = {
        FullName: formdata.fullname,
        WhatsAppNumber: formdata.whatsappnumber,
        Email: formdata.email,
        City: formdata.city,
        Designation: formdata.designation,
        Date: new Date(),
        // Location: window.location.href,
      };

      let formdataToSend = new FormData();
      for (let key in inputvalue) {
        formdataToSend.append(key, inputvalue[key]);
      }
      setLoading(true);
      try {
        await axios.post(
          // "https://script.google.com/macros/s/AKfycbzShuVhulpkNZuW_DL12QMxf9QlRtJNm37uDbVHNGgOyvfvGDFAENCyK6hRl5YMUxsz/exec",
          'https://script.google.com/macros/s/AKfycbwQOwstTegQRkxRsw6z4Lcgkc10IF70_QZ5Ar8roXGFqox4loZFuyooE3KnTKZOKohKSg/exec',
          formdataToSend
        );
        setLoading(false);
        // window.location.reload();
        setThankYouModal(true);
        setFormdata({
          fullname: "",
          whatsappnumber: "",
          email: "",
          city: "",
          designation: "",
        });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <div className="contact-page-content">
      <div className="contact-banner">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-10 d-flex align-items-center">
              <div className="contbanleftflex mt-5">
                <div className="contactbanleft">
                  <h1
                    className="contactlefthead"
                    data-aos="zoom-in"
                    data-aos-duration="2000"
                  >
                    Become a Digital Entrepreneur
                  </h1>
                  <p
                    className="contbanpara mt-4 mb-4"
                    data-aos="zoom-out"
                    data-aos-duration="2000"
                  >
                    Learn all the current strategies from the top experts
                    <br className="break-tag" />
                    and grow together with our Community
                  </p>
                  <div>
                    <button
                      className="downbtn"
                      data-aos="zoom-in"
                      data-aos-duration="2000"
                    >
                      <div className="downbtndiv">
                        <div>
                          <img
                            src={downleftpic} loading="lazy"
                            alt=".."
                            className="downleftpic"
                          />
                        </div>
                        <div
                          className="downlpara ps-2"
                          onClick={() => setOpen(true)}
                        >
                          <p>Download Our Brochure</p>
                          <div className="paddiv">
                            <p>PDF 2.0 MB</p>
                            <FontAwesomeIcon
                              icon={faDownload}
                              className="downicon"
                            />
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-10 ">
              <div
                className="contact-parent-div mt-5"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <form onSubmit={handleFormSubmit}>
                  <div className="inputscroll p-2">
                    <div className="contactinputs">
                      <label className="mt-0">Full Name*</label>
                      <input
                        type="text"
                        name="fullname"
                        placeholder="Enter First & Last Name"
                        value={formdata.fullname}
                        onChange={handlechange}
                      />
                      {validationError.fullname && (
                        <p className="errorpara">{validationError.fullname}</p>
                      )}
                    </div>
                    <div className="contactinputs">
                      <label>WhatsApp Number*</label>
                      <input
                        type="text"
                        name="whatsappnumber"
                        placeholder="Enter WhatsApp Number"
                        value={formdata.whatsappnumber}
                        onChange={handlechange}
                        maxLength={10}
                      />
                      {validationError.whatsappnumber && (
                        <p className="errorpara">
                          {validationError.whatsappnumber}
                        </p>
                      )}
                    </div>
                    <div className="contactinputs">
                      <label>Email*</label>
                      <input
                        type="text"
                        name="email"
                        placeholder="Enter Email Address"
                        value={formdata.email}
                        onChange={handlechange}
                      />
                      {validationError.email && (
                        <p className="errorpara">{validationError.email}</p>
                      )}
                    </div>
                    <div className="contactinputs">
                      <label>City*</label>
                      <input
                        type="text"
                        name="city"
                        placeholder="Enter City"
                        value={formdata.city}
                        onChange={handlechange}
                      />
                      {validationError.city && (
                        <p className="errorpara">{validationError.city}</p>
                      )}
                    </div>
                    <div className="contactinputs">
                      <label>Designation*</label>
                      <select
                        name="designation"
                        className="selects"
                        value={formdata.designation}
                        onChange={handlechange}
                      >
                        <option value="">Select Designation</option>
                        <option value="Entrepreneur">Entrepreneur</option>
                        <option value="Agency Owner">Agency Owner</option>
                        <option value="Freelancer">Freelancer</option>
                        <option value="Student">Student</option>
                        <option value="Side Hustle">Side Hustle</option>
                        <option value="Housewife">Housewife</option>
                      </select>
                      {validationError.designation && (
                        <p className="errorpara">
                          {validationError.designation}
                        </p>
                      )}
                    </div>
                  </div>
                  <button className="submitbtn" type="submit">
                    {loading ? (
                      <div className="spinner-border " role="status">
                        <span className="sr-only ">Loading...</span>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BrochureDownloadModal
        open={open}
        setClose={() => {
          setOpen(false);
        }}
      />
      <ThankYouModel
        open={thankYouModal}
        setOpen={() => {
          setThankYouModal(false);
        }}
      />
    </div>
  );
};

export default ContactBanner;
