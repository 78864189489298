import React from "react";
import Logo from "../../assets/images/logoblack.webp";
import Success from "../../assets/images/thanksbatchimg.webp";

import CustomModal from "./CustomModal";

const ThankYouModal = ({ open, setOpen }) => {
  return (
    <div>
      <CustomModal
        open={open}
        onClickOutside={() => {
          setOpen(false);
        }}
      >
        <div className="modal-content contact-container w-50 m-auto">
          <div className="modal-body p-0 ">
            <div className="text-center pt-3">
              <img  loading="lazy"  className="thnk-modal-logo" src={Logo} alt=""></img>
            </div>
            <hr />
            <div className="container ">
              <div className="row d-flex align-items-center justify-content-center  my-4">
                <div className="container px-5">
                  <div className="row justify-content-center  ">
                    <div className="col-lg-12 justify-content-center">
                      <div className="text-center">
                        <img  loading="lazy"  className="success-img" src={Success} alt=""></img>
                      </div>

                      <h1 className="text-center mb-3 font-bold">Thank You!</h1>
                      <p className="mb-0 text-center">
                        Your details has been successfully submitted. Thank You!
                      </p>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn  mt-5  w-100 btn-primary contact-btn"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Okay
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default ThankYouModal;
