import React from "react";
import scheludeicon from "../../assets/images/scheludeicon.webp";
import whatsappicon from "../../assets/images/whatsappicon.webp";
import CustomModal from "./CustomModal";
import { NavLink } from "react-router-dom";
// import Thankyou from '../../assets/images/thank.webp'
const SpeakWithUs = ({ open, setOpen }) => {
  return (
    <div className="speak-withus-drop">
      <CustomModal
        open={open}
        onClickOutside={() => {
          setOpen(false);
        }}
      >
        <div className="modal-content">
          <div className="close-btn">
            <i
              className="fa-regular fa-circle-xmark"
              onClick={() => setOpen(false)}
            ></i>
          </div>
          <div className="conlastdivmodel w-100">
            <h4 className="conlasthead ">
              When the Student is ready, the Teacher will appear
            </h4>
            <div className="meetingsflex container">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-7">
                  <div className="scheludemeeting">
                    <div className="meetingicondiv">
                      <img  loading="lazy"  src={scheludeicon} alt="../" />
                      <p className="meetingpara">Schedule Meeting</p>
                    </div>
                    <p className="meetingpara2 mb-4">
                      Book a Time & Speak with our Expert 1-1
                      <br className="break-tag" />
                      to understand how you can be a part of us
                    </p>
                    <a href="https://optin.socialeagle.in/#/apply" data-aos="fade-right" data-aos-duration="1500" target='_blank'>
                      <button className="expercallbtn">Book Expert Call</button>
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-7">
                  <div className="scheludemeeting ms-5">
                    <div className="meetingicondiv">
                      <img  loading="lazy"  src={whatsappicon} alt="../" />
                      <p className="meetingpara">WhatsApp Us</p>
                    </div>
                    <p className="meetingpara2 mb-4">
                      Say “Hi” over WhatsApp. We’re <br className="break-tag" />{" "}
                      just a text away.
                    </p>
                    <div className="">
                      <a
                        href="https://wa.link/fc88to"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button className="expercallbtn2">
                          WhatsApp us
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default SpeakWithUs;
