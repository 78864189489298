import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneVolume,
  faEnvelope,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";

import scheludeicon from "../../assets/images/scheludeicon.webp";
import whatsappicon from "../../assets/images/whatsappicon.webp";

const ContactContent = () => {
  return (
    <div className="">
      <section className="contactinfo" style={{ backgroundColor: "#F8F7F8" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-5">
              <div className="contacttopdiv">
                <div className="contactdiv">
                  <h2
                    className="contacth2"
                    data-aos="zoom-out-down"
                    data-aos-duration="1000"
                  >
                    Contact Info
                  </h2>
                  <div
                    className="contactflex mt-4"
                    data-aos="zoom-out-right"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon
                      icon={faPhoneVolume}
                      className="contacticon"
                    />
                    <a href="+91 8428421222" className="anchor ms-2">
                      +91 842 842 1222
                    </a>
                  </div>
                  <div
                    className="contactflex mt-3"
                    data-aos="zoom-out-left"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="contacticon"
                    />
                    <a
                      href="mailto:academy@socialeagle.in"
                      className="anchor ms-2"
                    >
                      academy@socialeagle.in
                    </a>
                  </div>
                  <div
                    className="contactflex mt-3"
                    data-aos="zoom-out-right"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      className="contacticon"
                    />
                    <p className="locationpara ms-2">
                      Social Eagle Academy
                      <br />
                      No. 14-16, Global Hospital Road Junction,
                      <br className="break-tag" />
                      {/* Corner Building (Above
                      G-World), <br className="break-tag" /> */}
                      Indira Priyadarshini Nagar, <br className="break-tag" />
                      Opp. ICICI Bank, <br className="break-tag" />
                      Perumbakkam, <br className="break-tag" />
                      Chennai, <br className="break-tag" />
                      Tamil Nadu 600100
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-7">
              <div className="googlemap">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d124449.25476974674!2d80.12575234233712!3d12.905200200446119!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3a525d14f00995f7%3A0xb37cd82fd02a1480!2sNo.14-16%2C%20Global%2C%20above%20fresh2day%2C%20Hospital%20Rd%2C%20Indira%20Priyadarshini%20Nagar%2C%20Perumbakkam%2C%20Chennai%2C%20Tamil%20Nadu%20600100!3m2!1d12.9052131!2d80.2081542!5e0!3m2!1sen!2sin!4v1721907695042!5m2!1sen!2sin"
                  allowfullscreen=""
                  loading="lazy"
                  title="df"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="conlastsection">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="conlastdiv">
                <h4
                  className="conlasthead"
                  data-aos="zoom-in-down"
                  data-aos-duration="1000"
                >
                  When the Student is ready, the Teacher will appear
                </h4>
                <div className="meetingsflex">
                  <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-7">
                      <div className="scheludemeeting">
                        <div
                          className="meetingicondiv"
                          data-aos="zoom-out-right"
                          data-aos-duration="2000"
                        >
                          <img src={scheludeicon} loading="lazy" alt="../" />
                          <p className="meetingpara">Schedule Meeting</p>
                        </div>
                        <p
                          className="meetingpara2"
                          data-aos="fade-up"
                          data-aos-duration="2000"
                        >
                          Book a Time & Speak with our Expert 1-1
                          <br className="break-tag" />
                          to understand how you can be a part of us
                        </p>
                        <a
                          href="https://optin.socialeagle.in/#/apply"
                          data-aos="fade-right"
                          data-aos-duration="1500"
                          target="_blank"
                        >
                          <button
                            className="expercallbtn"
                            data-aos="fade-up"
                            data-aos-duration="2000"
                          >
                            Book Expert Call
                          </button>
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-7">
                      <div className="scheludemeeting ms-5">
                        <div
                          className="meetingicondiv"
                          data-aos="zoom-out-left"
                          data-aos-duration="2000"
                        >
                          <img src={whatsappicon} loading="lazy" alt="../" />
                          <p className="meetingpara">WhatsApp Us</p>
                        </div>
                        <p
                          className="meetingpara2"
                          data-aos="fade-down"
                          data-aos-duration="2000"
                        >
                          Say “Hi” over WhatsApp. We’re just a
                          <br className="break-tag" />
                          text away.
                        </p>

                        {/* <button className="expercallbtn2"> */}
                        <div
                          className="mt-4"
                          data-aos="fade-down"
                          data-aos-duration="2500"
                        >
                          <a
                            className="expercallbtn2"
                            href="https://wa.link/fc88to"
                            target="_blank"
                            rel="noreferrer"
                          >
                            WhatsApp us
                          </a>
                        </div>
                        {/* </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactContent;
