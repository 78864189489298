import React, { useEffect } from 'react'
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import EventsBanner from '../Components/Events/EventsBanner';
import EventsContent from '../Components/Events/EventsContent';
import FacebookPixel from '../Components/FacebookPixel';

const Events = () => {
    useEffect(() => {
        window.scroll(0, 0);
    }, []);
    return (
        <div>
            <FacebookPixel/>
            <Navbar />
            <EventsBanner />
            <EventsContent />
            <Footer />
        </div>
    )
}

export default Events