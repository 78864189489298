import React, { useState } from "react";
import CustomModal from "../Model/CustomModal";
import ThankYouModal from "../Model/ThankYouModel";
import axios from "axios";
const BrochureDownloadModal = ({ open, setClose }) => {
  const [thankYouModal, setThankYouModal] = useState(false);
  const [data, setData] = useState({});
  const [validation, setValidation] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = async () => {
    let flag = true;
    setLoading(true);
    let validate = {};
    const currentDate = new Date();
    const nameRegex = /^[A-Za-z\s]+$/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Fullname validation

    console.log(data.name, "data.name");
    if (data.name?.length <= 0 || typeof data.name === "undefined") {
      flag = false;
      validate.name = "Name cannot be empty.";
    } else if (data?.name?.length < 3) {
      flag = false;
      validate.name = "Name must be at least 3 characters long.";
    } else if (!nameRegex?.test(data.name)) {
      flag = false;
      validate.name = "Name can only contain letters and spaces.";
    }

    // WhatsApp number validation
    if (
      data.phoneNumber?.length < 0 ||
      typeof data.phoneNumber === "undefined"
    ) {
      flag = false;
      validate.phoneNumber = "WhatsApp number cannot be empty.";
    } else if (!/^[6-9]/.test(data.phoneNumber)) {
      flag = false;
      validate.phoneNumber = "Invalid WhatsApp number";
    } else if (!/^\d{10}$/.test(data.phoneNumber)) {
      flag = false;
      validate.phoneNumber = "WhatsApp number must be exactly 10 digits.";
    }

    // Email validation
    if (data.emailId?.length < 0 || typeof data.emailId === "undefined") {
      flag = false;
      validate.emailId = "emailId cannot be empty.";
    } else if (!emailPattern.test(data.emailId)) {
      flag = false;
      validate.emailId = "Invalid emailId address.";
    }

    // City validation
    if (data.city?.length < 0 || typeof data.city === "undefined") {
      flag = false;
      validate.city = "City cannot be empty.";
    } else if (!nameRegex.test(data.city)) {
      flag = false;
      validate.city = "City can only contain letters and spaces.";
    }
    if (flag) {
      try {
        let inputValue = {
          Name: data.name,
          "Phone Number": data.phoneNumber,
          "Email Id": data.emailId,
          City: data.city,
          Date: currentDate.toDateString(),
        };

        // const Payload = [
        //   {
        //     Attribute: "FirstName",
        //     Value: data.name,
        //   },
        //   {
        //     Attribute: "EmailAddress",
        //     Value: data.emailId,
        //   },
        //   {
        //     Attribute: "Phone",
        //     Value: data.phoneNumber,
        //   },
        //   {
        //     Attribute: "City",
        //     Value: data.city,
        //   },
        //   {
        //     Attribute: "Source",
        //     Value: "Web-Academy-Download-Broucher",
        //   },
        // ];

        // try {
        //   const response = await axios.post(
        //     "https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$r6bb21b3cf1518343d1981ce6646b844f&secretKey=d58ccce3a29496917e48f84d2f40d31061c6b781",

        //     Payload,
        //     {
        //       headers: {
        //         Accept: "application/json",
        //         "Content-Type": "application/json",
        //       },
        //     }
        //   );
        // } catch (e) {
        //   console.error("Error sending to Leadsquared:", e);
        // }

        let form_data = new FormData();
        for (let key in inputValue) {
          form_data.append(key, inputValue[key]);
        }
        const response = await fetch(
          // "https://script.google.com/macros/s/AKfycbxUBWxRdM5qzkoEuZyoqnfkSHgHZnbnO2jq-KrOTJAuFihVMN_5cBv0S9rGkoxfGSrw/exec",
          "https://script.google.com/macros/s/AKfycbyrx-fKFlksslaKn1oSG-bcJW_XU1074pCh4-ifZDgd_ktrzM6yrDuAz3ApTAXCVLyKvA/exec",
          {
            method: "POST",
            body: form_data,
          }
        );

        await response.text();
        setLoading(false);
        setClose();
        setData({});
        setThankYouModal(true);
        window.open(
          "https://socialeagleacademy.in/SEA-Cohort-Brochure.pdf",
          "_blank"
        );
        download(
          "https://socialeagleacademy.in/SEA-Cohort-Brochure.pdf",
          "SEA-Cohort-Brochure.pdf"
        );
      } catch (error) {
        setLoading(false);
        console.error("Error writing to Google Sheets:", error);
      }
    } else {
      setLoading(false);
      setValidation(validate);
    }
  };

  const download = (path, filename) => {
    const anchor = document.createElement("a");
    anchor.href = path;
    anchor.download = filename;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };
  const setFieldData = (e, key) => {
    setData({ ...data, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  return (
    <div>
      <CustomModal
        open={open}
        onClickOutside={() => {
          setClose(false);
          setValidation({});
        }}
      >
        <div className="modal-content contact-container w-50 m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <span
                className="close-icon-broucher"
                style={{ textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px" }}
                onClick={() => {
                  setClose(false);
                  setValidation({});
                }}
              >
                <i class="fa-solid fa-xmark"></i>
              </span>
              <div className="row d-flex align-items-center justify-content-center  my-4">
                <div className="container px-lg-5 px-3">
                  <div className="row justify-content-center  height-modal ">
                    <h4 className="text-center font-bold sendhead">
                      Send us a Message
                    </h4>
                    <div className="col-lg-12 justify-content-center">
                      <div className="mb-1">
                        <label
                          htmlFor="name"
                          className="font-bold mb-1 broucher-label"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          onChange={(e) => {
                            setFieldData(e.target.value, "name");
                          }}
                        />
                        <p
                          style={{
                            color: "red",
                            marginBottom: "2px",
                            fontSize: "10px",
                          }}
                        >
                          {validation.name}
                        </p>
                      </div>
                      <div className="mb-1">
                        <label
                          htmlFor="phno"
                          className="font-bold mb-1 broucher-label"
                        >
                          WhatsApp Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="phno"
                          maxLength={10}
                          onChange={(e) => {
                            setFieldData(e.target.value, "phoneNumber");
                          }}
                        />
                        <p
                          style={{
                            color: "red",
                            marginBottom: "2px",
                            fontSize: "10px",
                          }}
                        >
                          {validation.phoneNumber}
                        </p>
                      </div>
                      <div className="mb-1">
                        <label
                          htmlFor="email"
                          className="font-bold mb-1 broucher-label"
                        >
                          Email
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          onChange={(e) => {
                            setFieldData(e.target.value, "emailId");
                          }}
                        />
                        <p
                          style={{
                            color: "red",
                            marginBottom: "2px",
                            fontSize: "10px",
                          }}
                        >
                          {validation.emailId}
                        </p>
                      </div>
                      <div className="mb-1">
                        <label
                          htmlFor="city"
                          className="font-bold mb-1 broucher-label"
                        >
                          City
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="city"
                          onChange={(e) => {
                            setFieldData(e.target.value, "city");
                          }}
                        />
                        <p
                          style={{
                            color: "red",
                            marginBottom: "2px",
                            fontSize: "10px",
                          }}
                        >
                          {validation.city}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn  mt-3  w-100 btn-primary contact-btn"
                      onClick={(inputIndex) => validate(inputIndex)}
                    >
                      {loading ? (
                        <div className="spinner-border2" role="status">
                          <span className="sr-only ">Loading...</span>
                        </div>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <ThankYouModal
        open={thankYouModal}
        setOpen={() => {
          setThankYouModal(false);
        }}
      />
    </div>
  );
};

export default BrochureDownloadModal;
