import React from "react";

const AboutUsBanner = () => {
  return (
    <div className="about-banner">
      <div className="mt-lg-0 mt-md-0 mt-5 ">
        <h1
          className="banner-heading mb-4"
          data-aos="zoom-in"
          data-aos-duration="1500"
        >
          About "Social Eagle Academy"
        </h1>
        <h5
          className="banner-sub-heading"
          data-aos="zoom-in"
          data-aos-duration="1500"
        >
          When the Student is Ready, The Teacher will Appear
        </h5>
      </div>
    </div>
  );
};

export default AboutUsBanner;
