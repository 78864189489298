import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import AboutEagleimg from "../../assets/images/abouteagleimg.webp";
import fouonderimg from "../../assets/images/fouonderimg.webp";
import aboutdirector1 from "../../assets/images/aboutdirector1.webp";
import aboutdirector2 from "../../assets/images/aboutdirector2.webp";
import ctoimg from "../../assets/images/ctoimg.webp";
import pedobrainimg from "../../assets/images/pedobrainimg.webp";
import pdopuzleimg from "../../assets/images/pdopuzleimg.webp";
import ubuntuimg from "../../assets/images/ubuntuimg.webp";
const AboutUsContent = () => {
  return (
    <div>
      <section className="aboutsection ">
        <div className="container">
          <div className="row justify-content-around">
            <div className="col-lg-6 col-md-6">
              <div className="aboutleft">
                <div>
                  <h2
                    className="aboutheading1 arial-bold"
                    data-aos="zoom-out-right"
                    data-aos-duration="1000"
                  >
                    About Social Eagle
                  </h2>
                  <p
                    className="aboutpara abtpara1 arial-regular"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    ‘Social Eagle’ is a brand that is Data-Driven,
                    Performance-Based, Customer-
                    <br className="abt-break-tag" />
                    Obsessed, and Result-Oriented that mainly focuses on
                    transforming People <br className="abt-break-tag" />
                    and Businesses through Digital Entrepreneurship and
                    Continuous Education.
                  </p>
                  <p
                    className="aboutpara arial-regular"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                  >
                    Being Customer-Centric and Value-Driven is the cornerstone
                    of our Results <br className="abt-break-tag" />
                    and Services.
                  </p>
                  <p
                    className="aboutpara arial-regular mb-3"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    Social Eagle, well known for Providing and Teaching Digital
                    Entrepreneurship, <br className="abt-break-tag" />
                    is strongly based on the following Values, that everyone who
                    is a part of <br className="abt-break-tag" /> ‘Social Eagle’
                    has imbibed.
                  </p>
                </div>
                <div className="aboutleft2 pt-4">
                  <div className="aboutspcbtwn">
                    <div
                      className="pointsflex"
                      data-aos="zoom-out-right"
                      data-aos-duration="1000"
                    >
                      <FontAwesomeIcon icon={faCheck} className="tickicons" />
                      <p className="abtpoints ">Customer Delight </p>
                    </div>
                    <div
                      className="pointsflex"
                      data-aos="zoom-out-left"
                      data-aos-duration="1000"
                    >
                      <FontAwesomeIcon icon={faCheck} className="tickicons" />
                      <p className="abtpoints ">Positive Contribution </p>
                    </div>
                  </div>
                  <div className="aboutspcbtwn">
                    <div
                      className="pointsflex"
                      data-aos="zoom-out-right"
                      data-aos-duration="1000"
                    >
                      <FontAwesomeIcon icon={faCheck} className="tickicons" />
                      <p className="abtpoints">Empathy </p>
                    </div>
                    <div
                      className="pointsflex"
                      data-aos="zoom-out-left"
                      data-aos-duration="1000"
                    >
                      <FontAwesomeIcon icon={faCheck} className="tickicons" />
                      <p className="abtpoints">Excellence </p>
                    </div>
                  </div>
                  <div className="aboutspcbtwn">
                    <div
                      className="pointsflex"
                      data-aos="zoom-out-right"
                      data-aos-duration="1000"
                    >
                      <FontAwesomeIcon icon={faCheck} className="tickicons" />
                      <p className="abtpoints">Competency </p>
                    </div>
                    <div
                      className="pointsflex"
                      data-aos="zoom-out-left"
                      data-aos-duration="1000"
                    >
                      <FontAwesomeIcon icon={faCheck} className="tickicons" />
                      <p className="abtpoints">Continuous Learning </p>
                    </div>
                  </div>
                  <div className="aboutspcbtwn">
                    <div
                      className="pointsflex"
                      data-aos="zoom-out-right"
                      data-aos-duration="1000"
                    >
                      <FontAwesomeIcon icon={faCheck} className="tickicons" />
                      <p className="abtpoints">Dynamism </p>
                    </div>
                    <div
                      className="pointsflex"
                      data-aos="zoom-out-left"
                      data-aos-duration="1000"
                    >
                      <FontAwesomeIcon icon={faCheck} className="tickicons" />
                      <p className="abtpoints">Innovation </p>
                    </div>
                  </div>
                  <div className="aboutspcbtwn">
                    <div
                      className="pointsflex"
                      data-aos="zoom-out-right"
                      data-aos-duration="1000"
                    >
                      <FontAwesomeIcon icon={faCheck} className="tickicons" />
                      <p className="abtpoints">Faith & Trust </p>
                    </div>
                    <div
                      className="pointsflex"
                      data-aos="zoom-out-left"
                      data-aos-duration="1000"
                    >
                      <FontAwesomeIcon icon={faCheck} className="tickicons" />
                      <p className="abtpoints">Ethics </p>
                    </div>
                  </div>
                  <div className="aboutspcbtwn">
                    <div
                      className="pointsflex"
                      data-aos="zoom-out-right"
                      data-aos-duration="1000"
                    >
                      <FontAwesomeIcon icon={faCheck} className="tickicons" />
                      <p className="abtpoints">Transparency </p>
                    </div>
                    <div
                      className="pointsflex"
                      data-aos="zoom-out-left"
                      data-aos-duration="1000"
                    >
                      <FontAwesomeIcon icon={faCheck} className="tickicons" />
                      <p className="abtpoints">Creativity </p>
                    </div>
                  </div>
                  <div className="aboutspcbtwn">
                    <div
                      className="pointsflex"
                      data-aos="zoom-out-right"
                      data-aos-duration="1000"
                    >
                      <FontAwesomeIcon icon={faCheck} className="tickicons" />
                      <p className="abtpoints">Discipline </p>
                    </div>
                    <div
                      className="pointsflex"
                      data-aos="zoom-out-left"
                      data-aos-duration="1000"
                    >
                      <FontAwesomeIcon icon={faCheck} className="tickicons" />
                      <p className="abtpoints">Determination </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-5">
              <div
                className="abteagle"
                data-aos="zoom-out-left"
                data-aos-duration="1000"
              >
                <img src={AboutEagleimg} alt=".." loading="lazy" className="abteagleimg" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="abtfounder secmargintop">
        <div className="container">
          <div className="row justify-content-around">
            <div className="col-lg-5">
              <div>
                <img
                  src={fouonderimg}
                  alt="..."
                  loading="lazy"
                  style={{ width: "100%", height: "50%" }}
                  data-aos="fade-down-right"
                  data-aos-duration="1000"
                />
                <div data-aos="fade-down" data-aos-duration="1000">
                  <h3 className="abtnames arial-bold">G D Dharaneetharan</h3>
                  <p className="abtroles arial-regular">Founder</p>
                  <p className="abtroles arial-regular">
                    Social Eagle & 21DC Community
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="founrghtleft">
                <h5
                  className="abtcfounderpara"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <span className="dharaniname  arial-bold">Dharani,</span>{" "}
                  fondly known as “Digital <br className="break-tag" />
                  Monk” hails from humble <br className="break-tag" />
                  beginnings from Sivakasi, the <br className="break-tag" />
                  “Kutty Japan” of Tamil Nadu.
                </h5>

                <div className="foundbtopara">
                  <div
                    className="foundflex"
                    data-aos="fade-down-left"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon icon={faCheck} className="tickicons" />
                    <p className="arial-regular">
                      Value-Providing Entrepreneur on a Mission
                      <br className="break-tag" />
                      to Redefine India’s Edtech Space
                    </p>
                  </div>
                  <div
                    className="foundflex"
                    data-aos="fade-down-right"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon icon={faCheck} className="tickicons" />
                    <p className="arial-regular">
                      Directly impacted 25000+ People through
                      <br className="break-tag" />
                      our Community
                    </p>
                  </div>
                  <div
                    className="foundflex"
                    data-aos="fade-down-left"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon icon={faCheck} className="tickicons" />
                    <p className="arial-regular">
                      Indirectly impacted 5L+ People through
                      <br className="break-tag" /> Organic High Value Content
                    </p>
                  </div>
                  <div
                    className="foundflex"
                    data-aos="fade-down-right"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon icon={faCheck} className="tickicons" />
                    <p className="arial-regular">
                      10+ years of Experience in the Industry{" "}
                    </p>
                  </div>
                  <div
                    className="foundflex"
                    data-aos="fade-down-left"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon icon={faCheck} className="tickicons" />
                    <p className="arial-regular">
                      “Best Digital Marketing Agency” Award by
                      <br className="break-tag" /> GMASA
                    </p>
                  </div>
                  <div
                    className="foundflex"
                    data-aos="fade-down-right"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon icon={faCheck} className="tickicons" />
                    <p className="arial-regular">
                      100+ Crores in Revenue for Clients
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="abtctop secmargintop">
        <div className="container">
          <div className="row justify-content-evenly">
            <div className="col-lg-5">
              <div className="ctodiv">
                <div>
                  <h5
                    className="abtcfounderpara"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    <span className="dharaniname">Vishnu Hari,</span> a
                    masterful tech <br className="break-tag" />
                    expert, is a jack of all trades when
                    <br className="break-tag" /> it comes to anything digital.
                    <br className="break-tag" />A lifelong learner, a
                    enthusiastic
                    <br className="break-tag" />
                    teacher, a total mentor, a true
                    <br className="break-tag" /> expert.
                  </h5>
                </div>
                <div className="foundbtopara">
                  <div
                    className="foundflex"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon icon={faCheck} className="tickicons" />
                    <p>Operations Manager to Digital Entrepreneur</p>
                  </div>
                  <div
                    className="foundflex"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon icon={faCheck} className="tickicons" />
                    <p>
                      Trained over 800 students on Digital
                      <br className="break-tag" />
                      Entrepreneurship
                    </p>
                  </div>
                  <div
                    className="foundflex"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon icon={faCheck} className="tickicons" />
                    <p>Helped Students earn Crores in Revenue</p>
                  </div>
                  <div
                    className="foundflex"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon icon={faCheck} className="tickicons" />
                    <p>
                      Worked with numerous International &
                      <br className="break-tag" /> Regional Brands.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div>
                <img
                  src={ctoimg}
                  loading="lazy"
                  alt="..."
                  style={{ width: "100%", height: "50%" }}
                  data-aos="zoom-out"
                  data-aos-duration="1000"
                />
                <div data-aos="fade-left" data-aos-duration="1000">
                  <h3 className="abtnames">Vishnu Hari</h3>
                  <p className="abtroles">CTO</p>
                  <p className="abtroles">Social Eagle</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="abtfounder secmargintop">
        <div className="container">
          <div className="row justify-content-around">
            <div className="col-lg-5">
              <div>
                <img
                  src={aboutdirector1}
                  loading="lazy"
                  alt="..."
                  style={{ width: "100%", height: "50%" }}
                  data-aos="fade-down-right"
                  data-aos-duration="1000"
                />
                <div data-aos="fade-down" data-aos-duration="1000">
                  <h3 className="abtnames arial-bold">Sathish S</h3>
                  <p className="abtroles arial-regular">Director, Operations</p>
                  <p className="abtroles arial-regular">Social Eagle</p>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="founrghtleft">
                <h5
                  className="abtcfounderpara"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <span className="dharaniname  arial-bold">Sathish S, </span>
                  a pure operator, <br className="break-tag" />
                  specialised in acquisition and
                  <br />
                  execution, is a true force of nature.
                  <br className="break-tag" />
                  Focused on getting things done, doing whatever it takes.
                </h5>

                <div className="foundbtopara">
                  <div
                    className="foundflex"
                    data-aos="fade-down-left"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon icon={faCheck} className="tickicons" />
                    <p className="arial-regular">
                      Mechanical Engineer to Digital Entrepreneur
                    </p>
                  </div>
                  <div
                    className="foundflex"
                    data-aos="fade-down-right"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon icon={faCheck} className="tickicons" />
                    <p className="arial-regular">
                      Executed Company Operations to scale to 8 figures
                    </p>
                  </div>
                  <div
                    className="foundflex"
                    data-aos="fade-down-left"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon icon={faCheck} className="tickicons" />
                    <p className="arial-regular">
                      Operations & Talent Acquisition Expert
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="abtctop secmargintop">
        <div className="container">
          <div className="row justify-content-evenly">
            <div className="col-lg-5">
              <div className="ctodiv">
                <div>
                  <h5
                    className="abtcfounderpara"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    <span className="dharaniname">Shatheeshram Ganapathy,</span>{" "}
                    a generalist who is keenly specialised in high-ticket
                    selling, lovingly called as “Sales Beast” by our Community,
                    is focused on efficiency, observational & experiential
                    learning and mentoring.
                  </h5>
                </div>
                <div className="foundbtopara">
                  <div
                    className="foundflex"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon icon={faCheck} className="tickicons" />
                    <p>
                      Aesthetic Photographer to a Process Oriented Entrepreneur
                    </p>
                  </div>
                  <div
                    className="foundflex"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon icon={faCheck} className="tickicons" />
                    <p>
                      Trained Sales Teams through 8 figure processes & systems
                    </p>
                  </div>
                  <div
                    className="foundflex"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon icon={faCheck} className="tickicons" />
                    <p>
                      Mentored Students to help them land consistent high-ticket
                      clients
                    </p>
                  </div>
                  <div
                    className="foundflex"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon icon={faCheck} className="tickicons" />
                    <p>
                      Focused on integrating creativity into everyday business
                      24/7
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div>
                <img
                  src={aboutdirector2}
                  loading="lazy"
                  alt="..."
                  style={{ width: "100%", height: "50%" }}
                  data-aos="zoom-out"
                  data-aos-duration="1000"
                />
                <div data-aos="fade-left" data-aos-duration="1000">
                  <h3 className="abtnames">Shatheeshram Ganapathy</h3>
                  <p className="abtroles">Director, Sales</p>
                  <p className="abtroles">Social Eagle</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pedalogysec secmargintop">
        <div className="container">
          <div className="row justify-content-evenly">
            <h2
              className="aboutheading2"
              data-aos="fade-down-left"
              data-aos-duration="1000"
            >
              Social Eagle’s Pedagogy
            </h2>
            <div className="col-lg-6">
              <div className="pedology-left-div">
                <div className="pedology-left-inner">
                  <div data-aos="zoom-in" data-aos-duration="1000">
                    <img src={pedobrainimg} loading="lazy" alt="../" />
                  </div>
                  <div
                    className="pedology-percent-div ms-5"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <h3 className="pedology-percent">80%</h3>
                    <p className="pedo-inner-para">Mindset</p>
                  </div>
                </div>
                <div className="pedology-left-inner mt-5">
                  <div
                    className="pedology-percent-div me-5"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                  >
                    <h3 className="pedology-percent">20%</h3>
                    <p className="pedo-inner-para">Skillset</p>
                  </div>
                  <div data-aos="zoom-out" data-aos-duration="1000">
                    <img src={pdopuzleimg} loading="lazy" alt="../" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="pedolgyrght pt-4">
                <h6
                  className="pedolgyhead"
                  data-aos="zoom-out-right"
                  data-aos-duration="1000"
                >
                  Pedagogy(n) - The method and <br className="break-tag" />
                  practice of teaching
                </h6>
                <p
                  className="pedolgypara"
                  data-aos="zoom-out-left"
                  data-aos-duration="1000"
                >
                  Skillset can only be put to good use with the right
                  <br className="break-tag" />
                  mindset.
                </p>
                <p data-aos="fade-right" data-aos-duration="1000">
                  We do not want either Mindset or Skillset to be the
                  <br className="break-tag" /> limiting factor.
                </p>
              </div>
              <div className="">
                <h6
                  className="pedolgyhead2"
                  data-aos="zoom-out-right"
                  data-aos-duration="1000"
                >
                  “Capture your mind and half the battle is won”
                </h6>
                <p
                  className="pedolgypara"
                  data-aos="zoom-out-left"
                  data-aos-duration="1000"
                >
                  During our school and college days, the teachers we
                  <br className="break-tag" />
                  loved the most are the ones who relieved our
                  <br className="break-tag" />
                  pressure. And through loving that teacher, we
                  <br className="break-tag" /> would have loved that subject!
                </p>
                <p
                  className="pedolgypara3"
                  data-aos="zoom-out-left"
                  data-aos-duration="1000"
                >
                  All of us at Social Eagle strive to be responsible
                  <br className="break-tag" /> teachers, first and foremost.
                </p>
                <p data-aos="zoom-out-left" data-aos-duration="1000">
                  And this responsibility makes us give hope and
                  <br className="break-tag" />
                  strategies that make our students win.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="ubuntusec secmargintop">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-10">
              <div className="ubuntudiv">
                <h2
                  className="aboutheading3"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  'I Am Because We Are': The African
                  <br className="break-tag" />
                  Philosophy of Ubuntu
                </h2>
                <img
                  src={ubuntuimg}
                  loading="lazy"
                  alt="..."
                  style={{ width: "100%", height: "auto" }}
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-duration="1000"
                />
                <p
                  className="abtubuntupara"
                  data-aos="zoom-out-right"
                  data-aos-duration="1000"
                >
                  A philosophy that supports collectivism over individualism.
                  <br className="break-tag" />
                  Ubuntu asserts that society gives human beings their humanity.
                </p>
                <p
                  className="pwrcommunity"
                  data-aos="zoom-out-left"
                  data-aos-duration="1000"
                >
                  The Power of Community:
                </p>
                <p
                  className="pwrcommpara"
                  data-aos="fade-down-right"
                  data-aos-duration="1000"
                >
                  We believe that when a powerful group of people who share the
                  same values and with a similar <br className="break-tag" />
                  mindset come together, who are all working together towards
                  the same goal, the combined energy
                  <br className="break-tag" /> becomes limitless. And the same
                  has been felt by our students where many call our Office as a
                  <br className="break-tag" />
                  <span style={{ fontWeight: 800 }}> “Temple of Energy”.</span>
                </p>
                <p
                  className="pwrcommpara"
                  data-aos="fade-down-left"
                  data-aos-duration="1000"
                >
                  Our Community is based on this Philosophy.
                  <br className="break-tag" /> The ability to literally interact
                  and share with anyone inside the Community gives people the
                  Power <br className="break-tag" />
                  and Support they want.
                </p>
                <p
                  className="pwrcommunity2"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  ‘ONE FOR ALL’ AND ‘ALL FOR ONE’
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="weteachsec secmarginbottom">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-10">
              <div className="ubuntudiv">
                <h5
                  className="howteach"
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                >
                  How we teach:
                </h5>
                <h2
                  className="aboutheading3"
                  data-aos="zoom-in-up"
                  data-aos-duration="1000"
                >
                  “When the student is ready, the <br className="break-tag" />
                  teacher will appear”
                </h2>
                <p
                  className="pwrcommpara"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  Our way of teaching is based entirely on personal
                  responsibility and taking ownership of
                  <br className="break-tag" />
                  one’s actions.
                </p>
                <p
                  className="pwrcommpara"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  Hence we focus on,
                </p>
              </div>
              <div className="aboutleft2">
                <div className="aboutspcbtwn">
                  <div
                    className="pointsflex"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon icon={faCheck} className="tickicons" />
                    <p className="abtpoints">Thinking Big </p>
                  </div>
                  <div
                    className="pointsflex"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon icon={faCheck} className="tickicons" />
                    <p className="abtpoints">Setting Systems & Processes </p>
                  </div>
                </div>
                <div className="aboutspcbtwn">
                  <div
                    className="pointsflex"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon icon={faCheck} className="tickicons" />
                    <p className="abtpoints">Working with the Right Intent </p>
                  </div>
                  <div
                    className="pointsflex"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon icon={faCheck} className="tickicons" />
                    <p className="abtpoints">Being Customer-Obsessed </p>
                  </div>
                </div>
                <div className="aboutspcbtwn">
                  <div
                    className="pointsflex"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon icon={faCheck} className="tickicons" />
                    <p className="abtpoints">Dedication to the job at hand </p>
                  </div>
                  <div
                    className="pointsflex"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon icon={faCheck} className="tickicons" />
                    <p className="abtpoints">Attacking the task at hand </p>
                  </div>
                </div>
                <div className="aboutspcbtwn">
                  <div
                    className="pointsflex"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon icon={faCheck} className="tickicons" />
                    <p className="abtpoints">Self-empowerment </p>
                  </div>
                  <div
                    className="pointsflex"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon icon={faCheck} className="tickicons" />
                    <p className="abtpoints">
                      Consistent effort on a daily basis
                    </p>
                  </div>
                </div>
                <div className="aboutspcbtwn">
                  <div
                    className="pointsflex"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon icon={faCheck} className="tickicons" />
                    <p className="abtpoints">Delayed Gratification </p>
                  </div>
                  <div
                    className="pointsflex"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon icon={faCheck} className="tickicons" />
                    <p className="abtpoints">Building bulletproof mindset </p>
                  </div>
                </div>
                <div className="aboutspcbtwn">
                  <div
                    className="pointsflex"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon icon={faCheck} className="tickicons" />
                    <p className="abtpoints">Working under Pressure </p>
                  </div>
                  <div
                    className="pointsflex"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                  >
                    <FontAwesomeIcon icon={faCheck} className="tickicons" />
                    <p className="abtpoints">Fostering Monk-like discipline </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUsContent;
