import React from "react";
import dgaranisimg from "../../assets/images/dgaranisimg.webp";
import facebook from "../../assets/images/facebook-icon.webp";
import instagram from "../../assets/images/instagram-icon.webp";
import linkedin from "../../assets/images/linkedin-icon.webp";
import vishnusimg from "../../assets/images/vishnusimg.webp";
import sathishimg from "../../assets/images/sathishimg.webp";
import shathesamsimg from "../../assets/images/shathesamsimg.webp";
import priyasimg from "../../assets/images/priyasimg.webp";
import ganapathisimg from "../../assets/images/ganapathisimg.webp";

import jameludinimg from "../../assets/images/jameludinimg.webp";
import sazrudinimg from "../../assets/images/azrudinimg.webp";
//
import sureshimg from "../../assets/images/sureshimg.webp";
import sakthiimg from "../../assets/images/sakthiimg.webp";
import rajsimg from "../../assets/images/rajsimg.webp";
import prabathimg from "../../assets/images/prabathimg.webp";

const ExpertsContent = () => {
  return (
    <div>
      <section className="Expertssection">
        <div className="container">
          <div className="row">
            <div className="expertfirsthead">
              <h2
                className="Expertheading1"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                Prime Experts
              </h2>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="expertsdiv">
                <img  loading="lazy" 
                  src={dgaranisimg}
                  alt=".."
                  className="exportimgs"
                  data-aos="fade-down"
                  data-aos-duration="2000"
                />
                <div
                  className="expertcard"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  <p className="expertsname">Dharaneetharan G D</p>
                  <p className="expertsrole">Founder & Thought Leader</p>
                  <div className="expertsiconsdiv">
                    <a
                      href="https://www.facebook.com/dharaneetharangd/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy"  src={facebook} alt=".../" className="expertsicon" />
                    </a>
                    <a
                      href="https://www.instagram.com/dharaneetharan/?hl=en"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy" 
                        src={instagram}
                        alt=".../"
                        className="expertsicon ms-4 me-4"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/dharaneetharan/?originalSubdomain=in"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy"  src={linkedin} alt=".../" className="expertsicon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="expertsdiv">
                <img  loading="lazy" 
                  src={vishnusimg}
                  alt=".."
                  className="exportimgs"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                />
                <div
                  className="expertcard"
                  data-aos="fade-down"
                  data-aos-duration="2000"
                >
                  <p className="expertsname">Vishnu Hari</p>
                  <p className="expertsrole">
                    Performance Marketer & Lead Trainer
                  </p>
                  <div className="expertsiconsdiv">
                    <a
                      href="https://www.facebook.com/imvishnuhari?mibextid=ZbWKwL"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy"  src={facebook} alt=".../" className="expertsicon" />
                    </a>
                    <a
                      href="https://www.instagram.com/imvishnuhari/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy" 
                        src={instagram}
                        alt=".../"
                        className="expertsicon ms-4 me-4"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/vishnuhari-harikumar/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy"  src={linkedin} alt=".../" className="expertsicon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="expertsdiv">
                <img  loading="lazy" 
                  src={sathishimg}
                  alt=".."
                  className="exportimgs"
                  data-aos="fade-down"
                  data-aos-duration="2000"
                />
                <div
                  className="expertcard"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  <p className="expertsname">Sathish S</p>
                  <p className="expertsrole">Operations & Acquisition Expert</p>
                  <div className="expertsiconsdiv">
                    <a
                      href="https://www.facebook.com/SocialEagleOfficial/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy"  src={facebook} alt=".../" className="expertsicon" />
                    </a>
                    <a
                      href="https://www.instagram.com/socialeagleofficial/?hl=en"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy" 
                        src={instagram}
                        alt=".../"
                        className="expertsicon ms-4 me-4"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/socialeagle/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy"  src={linkedin} alt=".../" className="expertsicon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="expertsdiv">
                <img  loading="lazy" 
                  src={shathesamsimg}
                  alt=".."
                  className="exportimgs"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                />
                <div
                  className="expertcardpadgana expertcard"
                  data-aos="fade-down"
                  data-aos-duration="2000"
                >
                  <p className="expertsname">Shatheeshram Ganapathy</p>
                  <p className="expertsrole">Sales Beast</p>
                  <div className="expertsiconsdiv">
                    <a
                      href="https://www.facebook.com/shatheeshram?mibextid=ZbWKwL"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy"  src={facebook} alt=".../" className="expertsicon" />
                    </a>
                    <a
                      href="https://www.instagram.com/shatheeshramganapathy?igsh=MW5nYzc4Nmh3OXJvdQ%3D%3D"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy" 
                        src={instagram}
                        alt=".../"
                        className="expertsicon ms-4 me-4"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/shatheeshram-ganapathy-a7476473/?original_referer=https%3A%2F%2Fwww.socialeagle.in%2F"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy"  src={linkedin} alt=".../" className="expertsicon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Expertssection2">
        <div className="container">
          <div className="row justify-content-center">
            <div className="expertsecondhead">
              <h2
                className="Expertheading1"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                Experts Sessions
              </h2>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="expertsdiv">
                <img  loading="lazy" 
                  src={priyasimg}
                  alt=".."
                  className="exportimgs"
                  data-aos="fade-right"
                  data-aos-duration="2000"
                />
                <div
                  className="expertcard"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  <p className="expertsname">Priya Dharaneetharan</p>
                  <p className="expertsrole">
                    Personal Branding & <br className="break-tag" /> Authority
                    Creator
                  </p>
                  <div className="expertsiconsdiv">
                    <a
                      href="https://www.facebook.com/priya.dharani.52?mibextid=YMEMSu"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy"  src={facebook} alt=".../" className="expertsicon" />
                    </a>
                    <a
                      href="https://www.instagram.com/priyadharaneetharan?igsh=eTg0NHJnMDQwZWxz"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy" 
                        src={instagram}
                        alt=".../"
                        className="expertsicon ms-4 me-4"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/priyadharani?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy"  src={linkedin} alt=".../" className="expertsicon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="expertsdiv">
                <img  loading="lazy" 
                  src={ganapathisimg}
                  alt=".."
                  className="exportimgs"
                  data-aos="zoom-in"
                  data-aos-duration="2000"
                />
                <div
                  className="expertcard expertcardpad"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                >
                  <p className="expertsname">Sudharsanan Ganapathy</p>
                  <p className="expertsrole">
                    Founder & CEO - The Social Company
                    <br className="break-tag" />
                    Personal Branding Expert
                  </p>
                  <div className="expertsiconsdiv">
                    <a
                      href="https://www.facebook.com/sudharinbox/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy"  src={facebook} alt=".../" className="expertsicon" />
                    </a>
                    <a
                      href="https://www.instagram.com/sudharsananganapathy/reels/?hl=en"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy" 
                        src={instagram}
                        alt=".../"
                        className="expertsicon ms-4 me-4"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/sudharsananganapathy/?originalSubdomain=in"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy"  src={linkedin} alt=".../" className="expertsicon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="expertsdiv">
                <img  loading="lazy" 
                  src={jameludinimg}
                  alt=".."
                  className="exportimgs"
                  data-aos="fade-right"
                  data-aos-duration="2000"
                />
                <div
                  className="expertcard expertcardpad"
                  data-aos="fade-down-left"
                  data-aos-duration="2000"
                >
                  <p className="expertsname">Jameluddin</p>
                  <p className="expertsrole">
                    Founder - Funnel God
                    <br className="break-tag" />7 Figure Funnel Expert
                  </p>
                  <div className="expertsiconsdiv">
                    <a
                      href="https://www.facebook.com/FunnelGod"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy"  src={facebook} alt=".../" className="expertsicon" />
                    </a>
                    <a
                      href="https://www.instagram.com/socialeagleofficial/?hl=en"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy" 
                        src={instagram}
                        alt=".../"
                        className="expertsicon ms-4 me-4"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/jamepreneur/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy"  src={linkedin} alt=".../" className="expertsicon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="expertsdiv">
                <img  loading="lazy" 
                  src={sazrudinimg}
                  alt=".."
                  className="exportimgs"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                />
                <div
                  className="expertcard"
                  data-aos="fade-down-right"
                  data-aos-duration="2000"
                >
                  <p className="expertsname">Azaruddin</p>
                  <p className="expertsrole">
                    Co-Founder - Propreneur
                    <br className="break-tag" /> Marketing Experimenter
                  </p>
                  <div className="expertsiconsdiv">
                    <a
                      href="https://www.facebook.com/azaruddindotcom"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy"  src={facebook} alt=".../" className="expertsicon" />
                    </a>
                    <a
                      href="https://www.instagram.com/socialeagleofficial/?hl=en"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy" 
                        src={instagram}
                        alt=".../"
                        className="expertsicon ms-4 me-4"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/azazar/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy"  src={linkedin} alt=".../" className="expertsicon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="Expertssection3">
        <div className="container">
          <div className="row justify-content-center">
            <div className="expertsecondhead">
              <h2
                className="Expertheading1"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                Special Lectures
              </h2>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="expertsdiv">
                <img  loading="lazy" 
                  src={sureshimg}
                  alt=".."
                  className="exportimgs"
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                />
                <div
                  className="expertcard"
                  data-aos="zoom-out-right"
                  data-aos-duration="1500"
                >
                  <p className="expertsname">Suresh Radhakrishnan</p>
                  <p className="expertsrole">
                    Entrepreneur, <br className="break-tag" /> Business Coach &
                    Creator
                  </p>
                  <div className="expertsiconsdiv">
                    <a
                      href="https://www.facebook.com/sureshradhakrishnansrk/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy"  src={facebook} alt=".../" className="expertsicon" />
                    </a>
                    <a
                      href="https://instagram.com/sureshradhakrishnansrk/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy" 
                        src={instagram}
                        alt=".../"
                        className="expertsicon ms-4 me-4"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/sureshradhakrishnansrk/?originalSubdomain=in"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy"  src={linkedin} alt=".../" className="expertsicon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="expertsdiv">
                <img  loading="lazy" 
                  src={sakthiimg}
                  alt=".."
                  className="exportimgs"
                  data-aos="zoom-out-up"
                  data-aos-duration="1500"
                />
                <div
                  className="expertcard expertcardpad"
                  data-aos="zoom-out-right"
                  data-aos-duration="1500"
                >
                  <p className="expertsname">Sakthi Ektha</p>
                  <p className="expertsrole">
                    Head of Content - The Social Company
                    <br className="break-tag" />
                    Content & Growth Strategist
                  </p>
                  <div className="expertsiconsdiv">
                    <a
                      href="https://www.facebook.com/SocialEagleOfficial/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy"  src={facebook} alt=".../" className="expertsicon" />
                    </a>
                    <a
                      href="https://www.instagram.com/sakthiektha/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy" 
                        src={instagram}
                        alt=".../"
                        className="expertsicon ms-4 me-4"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/sakthiektha/?originalSubdomain=in&original_referer=https%3A%2F%2Fwww.socialeagle.in%2F"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy"  src={linkedin} alt=".../" className="expertsicon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="expertsdiv">
                <img  loading="lazy" 
                  src={rajsimg}
                  alt=".."
                  className="exportimgs"
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                />
                <div
                  className="expertcard expertcardpad"
                  data-aos="zoom-out-left"
                  data-aos-duration="1500"
                >
                  <p className="expertsname">Raj Kumar</p>
                  <p className="expertsrole">
                    Founder - Neidhal.com
                    <br className="break-tag" /> E-commerce & Organic Marketing
                    Expert
                  </p>
                  <div className="expertsiconsdiv">
                    <a
                      href="https://www.facebook.com/SocialEagleOfficial/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy"  src={facebook} alt=".../" className="expertsicon" />
                    </a>
                    <a
                      href="https://www.instagram.com/neidhalrajkumar/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy" 
                        src={instagram}
                        alt=".../"
                        className="expertsicon ms-4 me-4"
                      />
                    </a>
                    <a
                      href="https://linkedin.com/company/socialeagle/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy"  src={linkedin} alt=".../" className="expertsicon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="expertsdiv">
                <img  loading="lazy" 
                  src={prabathimg}
                  alt=".."
                  className="exportimgs"
                  data-aos="fade-right"
                  data-aos-duration="2000"
                />
                <div
                  className="expertcard expertcardpad"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                >
                  <p className="expertsname">Prabath Krishnan</p>
                  <p className="expertsrole">
                    Founder - Reels & Shorts
                    <br className="break-tag" />
                    Video Story Teller
                  </p>
                  <div className="expertsiconsdiv">
                    <a
                      href="https://www.facebook.com/people/Prabath-Krishnan/pfbid02DpuCcMNZgJ67bCaxKhwGjhADSCDUSawhsDVM9JfiEpQzhh7bVugVLmXTPzbb811Bl/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy"  src={facebook} alt=".../" className="expertsicon" />
                    </a>
                    <a
                      href="https://www.instagram.com/prabathkrishnan/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy" 
                        src={instagram}
                        alt=".../"
                        className="expertsicon ms-4 me-4"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/socialeagle/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img  loading="lazy"  src={linkedin} alt=".../" className="expertsicon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </section>

      <section
        className="expertslastsec"
        style={{ backgroundColor: "#FBF9EB" }}
      >
        <div className="container">
          <div className="section-handson-sticky">
            <h2
              className="section-heading mb-5"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              Who is the ‘Digital Entrepreneurship
              <br className="break-tag" /> Initiator’ Program for??
            </h2>
          </div>
          <div className="row justify-content-center py-3">
            <div className="col-lg-6">
              <div className="">
                <h5
                  className="choose-us-sub-heading"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  Entrepreneurs
                </h5>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="">
                <p
                  className="choose-us-para-text"
                  data-aos="fade-down"
                  data-aos-duration="2000"
                >
                  Business Owners & Entrepreneurs who want to upskill and
                  <br className="break-tag" />
                  implement current Digital Marketing Strategies into their
                  <br className="break-tag" />
                  existing business.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className="row justify-content-center py-3">
            <div className="col-lg-6">
              <div className="">
                <h5
                  className="choose-us-sub-heading"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  Agency Owners
                </h5>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="">
                <p
                  className="choose-us-para-text"
                  data-aos="fade-down"
                  data-aos-duration="2000"
                >
                  Digital Marketing Agency Owners who want to update
                  <br className="break-tag" />
                  themselves, learn current advanced methods and charge
                  <br className="break-tag" />
                  high-ticket and build a 7 figure agency.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className="row justify-content-center py-3">
            <div className="col-lg-6">
              <div className="">
                <h5
                  className="choose-us-sub-heading"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  Freelancers
                </h5>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="">
                <p
                  className="choose-us-para-text"
                  data-aos="fade-down"
                  data-aos-duration="2000"
                >
                  Becoming a Freelancer & Freelancers who want to get the
                  <br className="break-tag" />
                  more clients by offering result-oriented solutions to
                  <br className="break-tag" />
                  businesses end-to-end.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className="row justify-content-center py-3">
            <div className="col-lg-6">
              <div className="">
                <h5
                  className="choose-us-sub-heading"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  Students
                </h5>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="">
                <p
                  className="choose-us-para-text"
                  data-aos="fade-down"
                  data-aos-duration="2000"
                >
                  Students fresh out of school or college who want to
                  <br className="break-tag" />
                  become a Digital Entrepreneur and who want to do a side
                  <br className="break-tag" />
                  hustle, and people who want to become a part of a
                  <br className="break-tag" />
                  knowledge-oriented community.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className="row justify-content-center py-3">
            <div className="col-lg-6">
              <div className="">
                <h5
                  className="choose-us-sub-heading"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  Side Hustlers
                </h5>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="">
                <p
                  className="choose-us-para-text"
                  data-aos="fade-down"
                  data-aos-duration="2000"
                >
                  Job goers who want to build a business on the side or
                  <br className="break-tag" />
                  part time and earn by learning all the Digital Strategies and{" "}
                  <br className="break-tag" />
                  client-handling skills and people who want to build a network.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className="row justify-content-center py-3">
            <div className="col-lg-6">
              <div className="">
                <h5
                  className="choose-us-sub-heading"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  Housewives
                </h5>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="">
                <p
                  className="choose-us-para-text"
                  data-aos="fade-down"
                  data-aos-duration="2000"
                >
                  Women who want to go to the next level after a career
                  <br className="break-tag" /> break by learning new digital
                  entrepreneurship skills and <br className="break-tag" />
                  becoming a part of the community through earning on the
                  <br className="break-tag" /> side or full-time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ExpertsContent;
