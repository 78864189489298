import React from 'react'
import { useNavigate } from 'react-router-dom'

const HomeBanner = () => {
    const navigation = useNavigate()
    return (
        <div className='home-banner overflow-hidden' >
            <div className=''>
                <h1 className='banner-heading mb-4 arial-bold' data-aos="zoom-out-up" data-aos-duration="1500">Become a Digital Entrepreneur</h1>
                <h5 className='banner-sub-heading mb-5 arial-regular' data-aos="zoom-out-up" data-aos-duration="1500">Learn Digital Marketing from the Top Industry Experts who <br className='break-tag' /> Practice what they Teach everyday</h5>
                <div className='flexRow'>
                    <a href="https://optin.socialeagle.in/#/apply" data-aos="fade-right" data-aos-duration="1500" target='_blank'>
                        <button type="button" class="btn btn-book-expert-call arial-bold">Book Expert Call</button>
                    </a>
                    <button onClick={() => navigation('Course')} data-aos="fade-left" data-aos-duration="1500" type="button " class="btn btn-explore-course arial-bold">Explore Course</button>
                </div>
            </div>
        </div>
    )
}

export default HomeBanner