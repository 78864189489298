import React, { useState, useRef, useEffect } from "react";
import "@wistia/wistia-player";
import "@wistia/wistia-player";
import { Link, useLocation, useNavigate } from "react-router-dom";

import PlayIcon from "../../assets/images/play-icon.webp";
import kk1 from "../../assets/images/tnds-k1.webp";
import kk2 from "../../assets/images/tnds-k2.webp";
import kk3 from "../../assets/images/tnds-k3.webp";
import kk4 from "../../assets/images/tnds-k4.webp";

import CustomModal from "../Model/CustomModal";
import PlayIconTesti from "../../assets/images/play-icon-testi.webp";

import EventsVideoThumb from "../../assets/images/events/events-video-thumb.webp";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import E1 from "../../assets/images/events/e1.webp";
import E2 from "../../assets/images/events/e2.webp";
import E3 from "../../assets/images/events/e3.webp";
import E4 from "../../assets/images/events/e4.webp";
import E5 from "../../assets/images/events/e5.webp";
import E6 from "../../assets/images/events/e6.webp";
import E7 from "../../assets/images/events/e7.webp";
import E8 from "../../assets/images/events/e8.webp";
import E9 from "../../assets/images/events/e9.webp";
import E10 from "../../assets/images/events/e10.webp";
import E11 from "../../assets/images/events/e11.webp";

import M1 from "../../assets/images/events/m1.webp";
import M2 from "../../assets/images/events/m2.webp";
import M3 from "../../assets/images/events/m3.webp";
import M4 from "../../assets/images/events/m4.webp";
import M5 from "../../assets/images/events/m5.webp";
import M6 from "../../assets/images/events/m6.webp";
import M7 from "../../assets/images/events/m7.webp";
import M8 from "../../assets/images/events/m8.webp";
import M9 from "../../assets/images/events/m9.webp";
import M10 from "../../assets/images/events/m10.webp";
import M11 from "../../assets/images/events/m11.webp";

import S1 from "../../assets/images/events/s1.webp";
import S2 from "../../assets/images/events/s2.webp";
import S3 from "../../assets/images/events/s3.webp";
import S4 from "../../assets/images/events/s4.webp";
import S5 from "../../assets/images/events/s5.webp";
import S6 from "../../assets/images/events/s6.webp";
import S7 from "../../assets/images/events/s7.webp";
import S8 from "../../assets/images/events/s8.webp";
import S9 from "../../assets/images/events/s9.webp";

import S12 from "../../assets/images/events/s12.webp";
import S13 from "../../assets/images/events/s13.webp";

import C1 from "../../assets/images/events/c1.webp";
import C2 from "../../assets/images/events/c2.webp";
import C3 from "../../assets/images/events/c3.webp";
import C4 from "../../assets/images/events/c4.webp";
import C5 from "../../assets/images/events/c5.webp";
import C6 from "../../assets/images/events/c6.webp";

import C8 from "../../assets/images/events/c8.webp";
import C9 from "../../assets/images/events/c19.webp";
import C10 from "../../assets/images/events/c10.webp";
import C11 from "../../assets/images/events/c11.webp";
import C12 from "../../assets/images/events/c12.webp";
import C13 from "../../assets/images/events/c13.webp";
import C14 from "../../assets/images/events/c14.webp";
import C15 from "../../assets/images/events/c15.webp";
import C16 from "../../assets/images/events/c16.webp";
import C17 from "../../assets/images/events/c17.webp";
import C18 from "../../assets/images/events/c18.webp";
import C19 from "../../assets/images/events/c19.webp";
import C20 from "../../assets/images/events/c20.webp";
import C21 from "../../assets/images/events/c21.webp";
import C22 from "../../assets/images/events/c22.webp";
import C23 from "../../assets/images/events/c23.webp";
import C24 from "../../assets/images/events/c24.webp";

import EventCommunityBanner from "../../assets/images/events/event-community-banner.webp";

const EventsContent = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const location = useLocation();
  const [activeSection, setActiveSection] = useState(
    location.search || "/Results?id=Videos"
  );

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll(".section");
      let currentSection = activeSection;
      const headerHeight =
        document.querySelector(".events-heading").offsetHeight + 85; // 85px is the 'top' offset in CSS

      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (
          rect.top <= window.innerHeight / 2 + headerHeight &&
          rect.bottom >= window.innerHeight / 2 + headerHeight
        ) {
          currentSection = `/Events?id=${section.id}`;
        }
      });

      if (currentSection !== activeSection) {
        setActiveSection(currentSection);
      }
      console.log("currentSection:", currentSection);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeSection]);

  useEffect(() => {
    if (location.search) {
      setActiveSection(location.search);
      const containerId = location.search.replace("?id=", "");
      const element = document.getElementById(containerId);
      if (element) {
        const headerHeight =
          document.querySelector(".events-heading").offsetHeight + 85;
        const elementPosition =
          element.getBoundingClientRect().top + window.scrollY - headerHeight;
        window.scrollTo({ top: elementPosition, behavior: "smooth" });
      }
    } else {
      setActiveSection("/Events?id=TNDS");
    }
  }, [location]);

  const getActiveClass = (searchParam) => {
    console.log(`Checking if ${activeSection} matches ${searchParam}`);
    return activeSection === searchParam ? "active" : "";
  };

  const [openVideo, setOpenVideo] = useState(false);
  const [currentMediaId, setCurrentMediaId] = useState(null);

  const getInspiredvideos = [
    {
      thumbImg: require("../../assets/images/events/testi/1.Maheswari.webp"),
      mediaId: "fsxcdnpyco",
    },
    {
      thumbImg: require("../../assets/images/events/testi/2.Santhosh.webp"),
      mediaId: "nsosfpn0gr",
    },
    {
      thumbImg: require("../../assets/images/events/testi/3.Uma& Jagadish.webp"),
      mediaId: "7k0l4qf95d",
    },
    {
      thumbImg: require("../../assets/images/events/testi/4.Nandhini Muthu.webp"),
      mediaId: "xr4e66el4r",
    },
    {
      thumbImg: require("../../assets/images/events/testi/5.Jamuna.webp"),
      mediaId: "1lcujd9xr4",
    },
    {
      thumbImg: require("../../assets/images/events/testi/6.kamesh.webp"),
      mediaId: "j3rlkuz526",
    },
    {
      thumbImg: require("../../assets/images/events/testi/7.RajaKumar.webp"),
      mediaId: "i7jkbyqq1q",
    },
    {
      thumbImg: require("../../assets/images/events/testi/8.JessyPriya.webp"),
      mediaId: "5ccjcl94kf",
    },
    {
      thumbImg: require("../../assets/images/events/testi/Thumbnail Convocation Success1.webp"),
      mediaId: "bbbjkwkxpi",
    },
    {
      thumbImg: require("../../assets/images/events/testi/Thumbnail Convocation Success11.webp"),
      mediaId: "0zh5tg5iku",
    },
    {
      thumbImg: require("../../assets/images/events/testi/Thumbnail Convocation Success6.webp"),
      mediaId: "vp584qkpwk",
    },
  ];

  const videos = [
    {
      thumbImg: require("../../assets/images/events/getInspired/1.Ganesh.webp"),
      mediaId: "iiwdw44krs",
    },
    {
      thumbImg: require("../../assets/images/events/getInspired/2.Richerd.webp"),
      mediaId: "t0w4xglaq3",
    },
    {
      thumbImg: require("../../assets/images/events/getInspired/3.Kamalakannan.webp"),
      mediaId: "l20ayojold",
    },
    {
      thumbImg: require("../../assets/images/events/getInspired/4.MarkMuthu.webp"),
      mediaId: "rkbrcfkbbu",
    },
    {
      thumbImg: require("../../assets/images/events/getInspired/5.Karthik.webp"),
      mediaId: "feeqyf9zwx",
    },
    {
      thumbImg: require("../../assets/images/events/getInspired/6.Ramesh.webp"),
      mediaId: "p1aij5wa3k",
    },
    {
      thumbImg: require("../../assets/images/events/getInspired/7.AlexVibin.webp"),
      mediaId: "q4vmukwqyn",
    },
    {
      thumbImg: require("../../assets/images/events/getInspired/8.Naveen.webp"),
      mediaId: "0kqepoya38",
    },
    {
      thumbImg: require("../../assets/images/events/getInspired/9.JeyaSudha.webp"),
      mediaId: "elrf50bxpu",
    },
    {
      thumbImg: require("../../assets/images/events/getInspired/10.KarthikRaja.webp"),
      mediaId: "pgdn4psbix",
    },
    {
      thumbImg: require("../../assets/images/events/getInspired/11.Vinothini.webp"),
      mediaId: "xzwl4nub16",
    },
    {
      thumbImg: require("../../assets/images/events/getInspired/12.PriyaKavichelvan.webp"),
      mediaId: "jflkf9tz4o",
    },
    {
      thumbImg: require("../../assets/images/events/getInspired/13.Gopikrishna.webp"),
      mediaId: "wiw4xz9qqj",
    },
    {
      thumbImg: require("../../assets/images/events/getInspired/14.Hariprasath.webp"),
      mediaId: "z79qaeuub9",
    },
  ];
  const navigate = useNavigate();

  return (
    <div className="results-content  py-5">
      <div className="container">
        <div className="events-heading p-3  mb-5">
          <div className="row justify-content-center align-items-center ">
            <div className="col-lg-6 text-center">
              <Link
                to="/Events?id=TNDS"
                data-aos="zoom-out-right"
                data-aos-duration="1500"
                className={`events-tab ${getActiveClass("/Events?id=TNDS")}`}
              >
                Tamil Nadu Digital Summit (TNDS){" "}
              </Link>
            </div>
            <div className="col-lg-6 text-center">
              <Link
                to="/Events?id=Convocation"
                data-aos="zoom-out-right"
                data-aos-duration="1500"
                className={`events-tab ${getActiveClass(
                  "/Events?id=Convocation"
                )}`}
              >
                Convocation
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-3 mb-5 section overflow-hidden" id="TNDS">
        <div className="container mb-5 ">
          <h5
            className="contacth2  mb-3"
            data-aos="zoom-out"
            data-aos-duration="1500"
          >
            What is TNDS?
          </h5>
          <div className="row ">
            <div
              className="col-lg-6"
              data-aos="fade-up-right"
              data-aos-duration="1500"
            >
              <p className="choose-us-para-text font-bold fs-4">
                ‘TN Digital Summit’ is Tamil Nadu's biggest & most valuable
                Digital Summit{" "}
              </p>
              <p className="choose-us-para-text">
                A Pure Value-Driven and Community-based event where Digital
                Marketing Enthusiasts come together to learn, network and
                upskill. The Awards night will feature Digital Influencers and
                Digital Entrepreneurs and the Networking Dinner will give more
                opportunities for knowledge-sharing. Eagle Awards will be
                presented to Community Members who have earned over 10L through
                using Digital Marketing.
              </p>
              <p className="choose-us-para-text">
                TN Digital Summit is all about sharing value and knowledge for
                the betterment of the Community, thereby contributing to the
                growth of everyone around us.
              </p>
            </div>
            <div
              className="col-lg-6"
              data-aos="fade-up-left"
              data-aos-duration="1500"
            >
              <div className="position-relative">
                {!isPlaying ? (
                  <div
                    onClick={() => setIsPlaying(true)}
                    style={{ cursor: "pointer" }}
                  >
                    <img  loading="lazy" 
                      src={EventsVideoThumb}
                      alt="Thumbnail"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "8px",
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <img  loading="lazy" 
                        className="play-icon-video "
                        src={PlayIcon}
                        alt=""
                      ></img>
                    </div>
                  </div>
                ) : (
                  <wistia-player
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "12px",
                    }}
                    media-id="dysmby51iv"
                    player-color="#D32B3A"
                  ></wistia-player>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="home-student-says-section container mb-5 ">
          <h5 className="contacth2  mb-4">Eagle Awards</h5>
          <div className="row mb-5">
            <div className="col-lg-4">
              <img  loading="lazy"  className="eagle-awards-img" src={E11} alt=""></img>
            </div>
            <div className="col-lg-4">
              <img  loading="lazy"  className="eagle-awards-img" src={E10} alt=""></img>
            </div>
            <div className="col-lg-4">
              <img  loading="lazy"  className="eagle-awards-img" src={E8} alt=""></img>
            </div>
            <div className="col-lg-3 ">
              <img  loading="lazy"  className="eagle-awards-img" src={E2} alt=""></img>
            </div>
            <div className="col-lg-6 my-auto ">
              <img  loading="lazy"  className="eagle-awards-img" src={E3} alt=""></img>
            </div>
            <div className="col-lg-3">
              <img  loading="lazy"  className="eagle-awards-img" src={E4} alt=""></img>
              <img  loading="lazy"  className="eagle-awards-img" src={E5} alt=""></img>
            </div>
            <div className="col-lg-3">
              <img  loading="lazy"  className="eagle-awards-img" src={E6} alt=""></img>
            </div>
            <div className="col-lg-3">
              <img  loading="lazy"  className="eagle-awards-img" src={E7} alt=""></img>
            </div>
            <div className="col-lg-3">
              <img  loading="lazy"  className="eagle-awards-img" src={E9} alt=""></img>
            </div>
            <div className="col-lg-3">
              <img  loading="lazy"  className="eagle-awards-img" src={E1} alt=""></img>
            </div>
            <div className="col-lg-4">
              <img  loading="lazy"  className="eagle-awards-img" src={M11} alt=""></img>
            </div>
            <div className="col-lg-4">
              <img  loading="lazy"  className="eagle-awards-img" src={M10} alt=""></img>
            </div>
            <div className="col-lg-4">
              <img  loading="lazy"  className="eagle-awards-img" src={M8} alt=""></img>
            </div>
            <div className="col-lg-3">
              <img  loading="lazy"  className="eagle-awards-img" src={M4} alt=""></img>
              <img  loading="lazy"  className="eagle-awards-img" src={M5} alt=""></img>
            </div>
            <div className="col-lg-6 my-auto ">
              <img  loading="lazy"  className="eagle-awards-img" src={M3} alt=""></img>
            </div>

            <div className="col-lg-3 ">
              <img  loading="lazy"  className="eagle-awards-img" src={M1} alt=""></img>
            </div>
            <div className="col-lg-3">
              <img  loading="lazy"  className="eagle-awards-img" src={M6} alt=""></img>
            </div>
            <div className="col-lg-3">
              <img  loading="lazy"  className="eagle-awards-img" src={kk1} alt=""></img>
            </div>
            <div className="col-lg-3">
              <img  loading="lazy"  className="eagle-awards-img" src={M7} alt=""></img>
            </div>
            <div className="col-lg-3">
              <img  loading="lazy"  className="eagle-awards-img" src={kk2} alt=""></img>
            </div>
            <div className="col-lg-3">
              <img  loading="lazy"  className="eagle-awards-img" src={M9} alt=""></img>
            </div>
            <div className="col-lg-3">
              <img  loading="lazy"  className="eagle-awards-img" src={kk3} alt=""></img>
            </div>
            <div className="col-lg-3">
              <img  loading="lazy"  className="eagle-awards-img" src={M2} alt=""></img>
            </div>

            <div className="col-lg-3">
              <img  loading="lazy"  className="eagle-awards-img" src={kk4} alt=""></img>
            </div>

            <div className="col-lg-4">
              <img  loading="lazy"  className="eagle-awards-img" src={S13} alt=""></img>
            </div>
            <div className="col-lg-4">
              <img  loading="lazy"  className="eagle-awards-img" src={S12} alt=""></img>
            </div>
            <div className="col-lg-4">
              <img  loading="lazy"  className="eagle-awards-img" src={S8} alt=""></img>
            </div>
            <div className="col-lg-3">
              <img  loading="lazy"  className="eagle-awards-img" src={S5} alt=""></img>
            </div>
            <div className="col-lg-6 my-auto ">
              <img  loading="lazy"  className="eagle-awards-img" src={S3} alt=""></img>
            </div>

            <div className="col-lg-3 ">
              <img  loading="lazy"  className="eagle-awards-img" src={S1} alt=""></img>
            </div>
            <div className="col-lg-4">
              <img  loading="lazy"  className="eagle-awards-img" src={S7} alt=""></img>
              <img  loading="lazy"  className="eagle-awards-img" src={S2} alt=""></img>
            </div>
            <div className="col-lg-4">
              <img  loading="lazy"  className="eagle-awards-img" src={S6} alt=""></img>
            </div>
            <div className="col-lg-4">
              <img  loading="lazy"  className="eagle-awards-img" src={S9} alt=""></img>
              <img  loading="lazy"  className="eagle-awards-img" src={S4} alt=""></img>
            </div>
          </div>
          <h5 className="contacth2  mb-4">Testimonials</h5>
          <div className="row ">
            <OwlCarousel
              className="owl-theme"
              loop
              autoplay={true}
              items="1"
              dots={false}
              smartSpeed={2000}
              autoplayHoverPause={true}
              nav={true}
              margin={20}
              center={true}
              autoplayTimeout={2000}
              responsive={{
                360: {
                  items: "1",
                },
                414: {
                  items: "1",
                },
                670: {
                  items: "2",
                },
                992: {
                  items: "2",
                },
                1200: {
                  items: "3",
                },
              }}
            >
              {videos.map((item, index) => {
                return (
                  <div data-aos="fade-up" data-aos-duration="2000" key={index}>
                    <div className="">
                      <div
                        className=""
                        onClick={() => {
                          setOpenVideo(true);
                          setCurrentMediaId(item.mediaId);
                        }}
                      >
                        <div className="position-relative">
                          <div>
                            <img  loading="lazy" 
                              src={item.thumbImg}
                              alt="Thumbnail"
                              className="testi-events"
                            />
                            <div
                              style={{
                                position: "absolute",
                                bottom: "5%",
                                left: "3%",
                              }}
                            >
                              <img  loading="lazy" 
                                className="play-icon-video-testi pulse-button"
                                src={PlayIconTesti}
                                alt=""
                              ></img>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
          <div className="text-center mt-4">
            <a
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSceIQVWu_fQOdywRibhWXg2ABettgnMCecsvHRtr0oDzWgEvw/viewform"
            >
              <button type="button" class="btn btn-explore-course">
                Apply for the “Eagle Awards”
              </button>
            </a>
          </div>
        </div>
        <div className="bg-scope-tnds-sections py-5">
          <div className="container">
            <div className="d-flex justify-content-center">
              <h5 className="contacth2 mb-5">The Significance</h5>
            </div>
            <div className="row justify-content-center mb-5">
              <div className="col-lg-10">
                <div className="row  py-2">
                  <div className="col-lg-5">
                    <h5
                      className="scope-heading"
                      data-aos="zoom-out-right"
                      data-aos-duration="1500"
                    >
                      Scope and Diversity
                    </h5>
                  </div>
                  <div className="col-lg-7">
                    <p
                      className="scope-para mb-0"
                      data-aos="zoom-out-right"
                      data-aos-duration="1500"
                    >
                      The summit covers a broad range of topics encompassing
                      digital marketing, digital sales, tech tools, e-commerce,
                      entrepreneurship, content creation, and more. Its
                      comprehensive agenda ensures that it appeals to a diverse
                      audience with varied interests and expertise within the
                      digital space.
                    </p>
                  </div>
                </div>
                <hr />
                <div className="row   py-2">
                  <div className="col-lg-5">
                    <h5
                      className="scope-heading"
                      data-aos="zoom-out-left"
                      data-aos-duration="1500"
                    >
                      High-profile Speakers and Participants
                    </h5>
                  </div>
                  <div className="col-lg-7">
                    <p
                      className="scope-para mb-0"
                      data-aos="zoom-out-left"
                      data-aos-duration="1500"
                    >
                      The summit attracts top-tier speakers, thought leaders,
                      and industry experts who share their insights,
                      experiences, and strategies for success.
                    </p>
                  </div>
                </div>
                <hr />
                <div className="row     py-2">
                  <div className="col-lg-5">
                    <h5
                      className="scope-heading"
                      data-aos="zoom-out-right"
                      data-aos-duration="1500"
                    >
                      Networking Opportunities
                    </h5>
                  </div>
                  <div className="col-lg-7">
                    <p
                      className="scope-para mb-0"
                      data-aos="zoom-out-right"
                      data-aos-duration="1500"
                    >
                      With a large attendance of professionals and stakeholders
                      from across the digital landscape, the summit provides
                      unparalleled networking opportunities. Attendees can
                      connect with peers, potential collaborators, clients, and
                      mentors, fostering valuable relationships that can lead to
                      business growth and professional development.
                    </p>
                  </div>
                </div>
                <hr />
                <div className="row   py-2">
                  <div className="col-lg-5">
                    <h5
                      className="scope-heading"
                      data-aos="zoom-out-left"
                      data-aos-duration="1500"
                    >
                      Innovation and Trends
                    </h5>
                  </div>
                  <div className="col-lg-7">
                    <p
                      className="scope-para mb-0"
                      data-aos="zoom-out-left"
                      data-aos-duration="1500"
                    >
                      The summit serves as a platform for showcasing the latest
                      innovations, trends, and technologies shaping the digital
                      industry. From cutting-edge tools and platforms to
                      emerging best practices, attendees gain valuable insights
                      into staying ahead of the curve and driving innovation for
                      their growth.
                    </p>
                  </div>
                </div>
                <hr />
                <div className="row  py-2">
                  <div className="col-lg-5">
                    <h5
                      className="scope-heading"
                      data-aos="zoom-out-right"
                      data-aos-duration="1500"
                    >
                      Scope and Diversity
                    </h5>
                  </div>
                  <div className="col-lg-7">
                    <p
                      className="scope-para mb-0"
                      data-aos="zoom-out-right"
                      data-aos-duration="1500"
                    >
                      Given its stature as the biggest event in the digital
                      space, the TN Digital Summit exerts a significant
                      influence on the direction and evolution of the digital
                      ecosystem. It serves as a catalyst for change, driving
                      conversations, collaborations, and initiatives that propel
                      the industry forward.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center">
              <a href="https://forms.gle/TgenoY8ANgMy8ce39" target="_blank">
                <button type="button" class="btn btn-explore-course">
                  Join the Waiting List
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="tnds-2025 py-5">
          <div className="container ">
            <div className="row">
              <div className="col-lg-8 my-auto">
                <div data-aos="zoom-out-down" data-aos-duration="1500">
                  <h5 className="font-bold text-white mb-2">
                    Join us at TNDS 2025
                  </h5>
                  <h1 className="font-bold text-white mt-2">
                    Want to attend TNDS & become a part of the Community?
                  </h1>
                </div>
              </div>
              <div className="col-lg-4 my-auto">
                <div data-aos="zoom-out-left" data-aos-duration="1500">
                  {/* <p className='choose-us-para-text text-white pb-4'>Want to attend TNDS & become a part of the Community?</p> */}
                  <a href="https://forms.gle/CwoRA61CFBQEHK8s5" target="_blank">
                    <button type="button" class="btn btn-book-expert-call ">
                      Apply for Tickets{" "}
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section" id="Convocation">
        <div className="container">
          <h5 className="contacth2  mt-2">Convocation</h5>
          <h5 className="font-bold mb-2">Where the Eagles come together</h5>
          <h5 className="font-bold mb-3">
            An opportunity to connect, network & to be recognised{" "}
          </h5>
          <h6 className=" mb-2">
            Students who have learnt Digital Strategies from ‘Social Eagle
            Academy’ and those who have successfully completed the Course become
            a part of the Convocation.
          </h6>
          <h6 className=" mb-5">
            The Convocation is presided over by Top Experts of the field and
            Prominent Entrepreneurs and knowledge is shared throughout.
          </h6>
          <div className="row mb-5">
            <div className="col-lg-4">
              <img  loading="lazy"  className="eagle-awards-img" src={C1} alt=""></img>
            </div>
            <div className="col-lg-4">
              <img  loading="lazy"  className="eagle-awards-img" src={C6} alt=""></img>
            </div>
            <div className="col-lg-4">
              <img  loading="lazy"  className="eagle-awards-img" src={C3} alt=""></img>
            </div>
            <div className="col-lg-3 ">
              <img  loading="lazy"  className="eagle-awards-img" src={C5} alt=""></img>
            </div>
            <div className="col-lg-6 mt-auto ">
              <img  loading="lazy"  className="eagle-awards-img" src={C2} alt=""></img>
            </div>
            <div className="col-lg-3">
              <img  loading="lazy"  className="eagle-awards-img" src={C14} alt=""></img>
            </div>
            <div className="col-lg-3">
              <img  loading="lazy"  className="eagle-awards-img" src={C8} alt=""></img>
            </div>
            <div className="col-lg-3">
              <img  loading="lazy"  className="eagle-awards-img" src={C9} alt=""></img>
            </div>
            <div className="col-lg-3">
              <img  loading="lazy"  className="eagle-awards-img" src={C10} alt=""></img>
            </div>
            <div className="col-lg-3">
              <img  loading="lazy"  className="eagle-awards-img" src={C4} alt=""></img>
            </div>
            <div className="col-lg-3">
              <img  loading="lazy"  className="eagle-awards-img" src={C13} alt=""></img>
              <img  loading="lazy"  className="eagle-awards-img" src={C15} alt=""></img>
            </div>
            <div className="col-lg-6">
              <img  loading="lazy"  className="eagle-awards-img" src={C12} alt=""></img>
            </div>
            <div className="col-lg-3">
              <img  loading="lazy"  className="eagle-awards-img" src={C17} alt=""></img>
              <img  loading="lazy"  className="eagle-awards-img" src={C16} alt=""></img>
            </div>
            <div className="col-lg-2">
              <img  loading="lazy"  className="eagle-awards-img" src={C18} alt=""></img>
            </div>
            <div className="col-lg-6">
              <img  loading="lazy"  className="eagle-awards-img" src={C11} alt=""></img>
            </div>
            <div className="col-lg-4">
              <img  loading="lazy"  className="eagle-awards-img" src={C24} alt=""></img>
            </div>
            <div className="col-lg-3">
              <img  loading="lazy"  className="eagle-awards-img" src={C20} alt=""></img>
              <img  loading="lazy"  className="eagle-awards-img" src={C21} alt=""></img>
            </div>
            <div className="col-lg-6">
              <img  loading="lazy"  className="eagle-awards-img" src={C19} alt=""></img>
            </div>
            <div className="col-lg-3">
              <img  loading="lazy"  className="eagle-awards-img" src={C22} alt=""></img>
              <img  loading="lazy"  className="eagle-awards-img" src={C23} alt=""></img>
            </div>
          </div>
          <h5 className="contacth2  mt-2 mb-5">
            Become a part of our Community
          </h5>
          <div className="row ">
            <div className="col-lg-12">
              <div className="position-relative">
                <img  loading="lazy" 
                  className="eagle-awards-img"
                  src={EventCommunityBanner}
                  alt=""
                ></img>
              </div>
            </div>
          </div>
          <div className="text-center mt-4 mb-5">
            <button
              onClick={() => navigate("/Course")}
              type="button"
              class="btn btn-explore-course"
            >
              Explore Course
            </button>
          </div>

          <div className="home-student-says-section">
            <h5 className="contacth2   mb-5">Get Inspired</h5>
            <div className="row mb-5">
              <div className="row ">
                <OwlCarousel
                  className="owl-theme"
                  loop
                  autoplay={true}
                  items="1"
                  dots={false}
                  smartSpeed={2000}
                  autoplayHoverPause={true}
                  nav={true}
                  margin={20}
                  center={true}
                  autoplayTimeout={2000}
                  responsive={{
                    360: {
                      items: "1",
                    },
                    414: {
                      items: "1",
                    },
                    670: {
                      items: "2",
                    },
                    992: {
                      items: "2",
                    },
                    1200: {
                      items: "3",
                    },
                  }}
                >
                  {getInspiredvideos.map((item, index) => {
                    console.log(item.thumbImg, item.mediaId);
                    return (
                      <div
                        data-aos="fade-up"
                        data-aos-duration="2000"
                        key={index}
                      >
                        <div className="">
                          <div
                            className=""
                            onClick={() => {
                              setOpenVideo(true);
                              setCurrentMediaId(item.mediaId);
                            }}
                          >
                            <div className="position-relative">
                              <div>
                                <img  loading="lazy" 
                                  src={item.thumbImg}
                                  alt="Thumbnail"
                                  className="testi-events"
                                />
                                <div
                                  style={{
                                    position: "absolute",
                                    bottom: "5%",
                                    left: "3%",
                                  }}
                                >
                                  <img  loading="lazy" 
                                    className="play-icon-video-testi pulse-button"
                                    src={PlayIconTesti}
                                    alt=""
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        open={openVideo}
        onClickOutside={() => {
          setOpenVideo(false);
          setCurrentMediaId(null);
        }}
      >
        <div className="modal-content contact-container m-auto">
          <div className="modal-body w-100 p-0 ">
            <div className="close-btn">
              <i
                className="fa-regular fa-circle-xmark"
                onClick={() => setOpenVideo(false)}
              ></i>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="">
                    <wistia-player
                      style={{
                        width: "100%",
                        height: 420,
                        borderRadius: 12,
                      }}
                      media-id={currentMediaId}
                      player-color="#D32B3A"
                    ></wistia-player>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default EventsContent;
